<script>
import {createFliggyCaseApi, getFliggyCaseListApi} from "@/www/urls/workOrder";
import {fliggyCategoryOptions, fliggyAttributesKey} from "@/data/workOrder";
import ImageUpload from "@/components/imageUpload/index.vue";
import FileUploadMultiple from "@/components/fileUploadMultiple/index.vue";

const initForm = () => {
    return {
        relationType: 'startRelation',
        categoryId: '',
        categoryName: '',
        images: [],
        files: []
    }
}
export default {
    name: "SendMessage",
    props: ['orderInfo', 'relationType'],
    components: {
        ImageUpload,
        FileUploadMultiple
    },
    data() {
        return {
            fliggyCategoryOptions,
            form: {},
            relationTypeOptions: [
                {
                    value: 'startRelation',
                    label: '需协同处理'
                },
                {
                    value: 'urgeCase',
                    label: '催促'
                },
                {
                    value: '',
                    label: '退回'
                },
                {
                    value: '',
                    label: '结束协同'
                },
                {
                    value: 'result',
                    label: '结果通知'
                }
            ],
            rules: {
                categoryId: [{required: true, message: '请选择协同类型', trigger: 'blur'}],
                content: [{required: true, message: '请输入需要协同处理的问题或内容'}]
            },
            loading: false,
            addLoading: false,
            fliggyCaseList: []
        }
    },
    computed: {
        currentWorkOrder() {
            return this.fliggyCaseList.find(({relationType}) => ['startRelation', 'reply', 'urgeCase'].includes(relationType))
        }
    },
    mounted() {
        this.getData();
        this.form.relationType = this.relationType;
        this.form = initForm()
        if (this.orderInfo) {
            this.form.thirdOrderId = this.orderInfo.thirdOrderInfoDto.thirdOrderId
        }
    },
    methods: {
        handleCancel() {
            this.$emit('onCancel')
        },
        handleCategoryChange(val, opt) {
            this.form.categoryName = opt.label
        },
        async handleSubmit() {
            try {
                this.addLoading = true;
                await this.$refs['form'].validate();
                const attributes = {};
                attributes[fliggyAttributesKey[this.relationType]] = this.form.content;
                attributes['imgUrlList'] = this.form.images.map(({imageUrl}) => imageUrl).filter((item) => item);
                attributes['fileUrlList'] = this.form.files;
                const f = {
                    ...this.form,
                    orderNo: this.orderInfo.orderNo,
                    orderId: this.orderInfo.orderId,
                    thirdOrderId: this.orderInfo.thirdOrderInfoDto.thirdOrderId,
                    fliggyCaseContent: JSON.stringify(attributes)
                }
                delete f.images
                delete f.files
                delete f.content
                console.log(f);
                const ret = await createFliggyCaseApi(f)
                if (ret.success && ret.data) {
                    this.$message.success('工单创建成功！')
                    this.$emit('onSuccess')
                    this.getData();
                } else {
                    this.$message.error(ret.errors[0].message)
                }
            } finally {
                this.addLoading = false;
            }
        },
        async getData() {
            try {
                this.loading = true;
                const ret = await getFliggyCaseListApi({
                    orderNo: this.orderInfo.orderNo,
                    pageSize: 1000
                })
                if (ret.success) {
                    this.fliggyCaseList = ret.data.data
                } else this.$message.error(ret.errors[0].message)
            } finally {
                this.loading = false
            }
        },
        handleGoTo() {
            this.$router.push(`/workOrderFliggy?orderNo=${this.currentWorkOrder.orderNo}`)
        }
    }
}
</script>

<template>
    <div class="fliggy-send-message">
        <p class="color-second mb15 font_s14">{{ currentWorkOrder ? '协同进行中' : '发起协同' }}</p>
        <div v-if="!loading">
            <el-form :model="form" :rules="rules" ref="form" size="small" label-width="110px" v-if="!currentWorkOrder">
                <el-form-item label="飞猪订单号：" required>
                    <el-input disabled :value="form.thirdOrderId" style="width: 400px"></el-input>
                </el-form-item>
                <el-form-item label="协同类型：" prop="categoryId">
                    <ApiSelect :options="fliggyCategoryOptions" v-model="form.categoryId"
                               placeholder="请选择协同类型"
                               filterable
                               style="width: 400px"
                               @change="handleCategoryChange"></ApiSelect>
                </el-form-item>
                <el-form-item label="协同问题：" prop="content">
                    <el-input type="textarea" v-model="form.content"
                              placeholder="请输入需要协同处理的问题或内容" style="width: 400px"></el-input>
                </el-form-item>
                <el-form-item label="上传图片：">
                    <image-upload :max="9" @update:imageUploadCompleted="form.images=$event"></image-upload>
                </el-form-item>
                <el-form-item label="上传文件：">
                    <div style="width: 400px">
                        <fileUpload-multiple v-model="form.files" accept="*"></fileUpload-multiple>
                    </div>
                </el-form-item>
                <el-form-item style="display: flex;justify-content: flex-end">
                    <el-button type="primary" :loading="addLoading" plain @click="handleCancel">取消</el-button>
                    <el-button type="primary" :loading="addLoading" @click="handleSubmit">确定</el-button>
                </el-form-item>
            </el-form>
            <div v-else style="display:flex;align-items: center">
                <div class="font_s12 color-gary" style="flex: 1">
                    本次协同工单完结后，可发起新的协同工单。
                </div>
                <div>
                    <el-button size="small" type="primary" plain @click="handleCancel">取消</el-button>
                    <el-button size="small" type="primary" @click="handleGoTo">去工单列表查看</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.fliggy-send-message {
    padding: 0 15px;

    &::v-deep {
        .el-form-item {
            margin-bottom: 18px !important;
        }
    }
}
</style>
