<style lang="scss" scoped>
.order-search-form {
    background: #ffffff;
    padding: 10px 20px 20px 10px;

    .screen-company-btn {
        /*float: right;*/
        position: relative;
        height: 40px;

        .screen-operate-sort {
            position: absolute;
            bottom: 0;
            left: 10px;
            font-size: 12px;

            .el-button {
                padding: 8px 20px;
            }

            .i-top {
                position: absolute;
                right: 5px;
                top: 5px;
            }

            .i-bottom {
                position: absolute;
                right: 5px;
                bottom: 5px;
            }
        }

        .screen-operate-btn {
            position: absolute;
            bottom: 0;
            right: 0;

            .el-button {
                padding: 8px 20px;
            }
        }
    }
}
</style>
<template>
    <div>
        <div class="order-search-form">
            <div>
                <el-form :inline="true" size="small">
                    <el-form-item>
                        <el-select v-model="searchForm.queryDay" @change="handleTimeInterval">
                            <el-option v-for="item in timeIntervals"
                                       :key="item.value"
                                       :label="item.label"
                                       :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item v-if="userInfo.userType==0||userInfo.userType==11">
                        <el-select v-model="searchForm.productOperatorId"
                                   clearable
                                   style="width: 160px"
                                   filterable
                                   @change="selectOperator"
                                   no-match-text="该运营商不存在"
                                   placeholder="运营商">
                            <el-option
                                v-for="item in operatorList"
                                :key="item.id"
                                :label="item.companyName"
                                :value="item.refId">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item v-if="userInfo.userType!=1">
                        <el-select v-model="searchForm.resourceSupplierId"
                                   clearable
                                   @change="selectSupplier"
                                   @visible-change="handleShowSupplierList"
                                   :filterable="true"
                                   no-match-text="该供应商不存在"
                                   style="width: 350px"
                                   placeholder="供应商">
                            <el-option
                                v-for="item in supplierList"
                                :key="item.id"
                                :label="item.supplierName"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div style="font-size: 14px;line-height: 35px">
                <el-tabs v-model="searchForm.orderStatus" @tab-click="selectRadio">
                    <el-tab-pane v-for="os in orderStatusListData" :key="os.value" :name="os.value">
                        <span slot="label"> {{ os.name }}
                            <span style="color: #ff3b30;"
                                  v-if="os.name!='全部'&&os.value!=='51'&&os.value!=='61'">{{ os.num }}</span>
                        </span>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <el-form :inline="true" size="small">
                <div>
                    <el-form-item v-if="searchForm.orderStatus==='71'">
                        <el-radio-group v-model="searchForm.cancelStatus" @change="selectRadio1">
                            <el-radio-button label="0">
                                全部
                            </el-radio-button>
                            <el-radio-button :label="os.value" v-for="os in cacelStatusList" :key="os.value">
                                {{ os.name }}
                            </el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item v-if="searchForm.orderStatus==='22'&&userInfo.refType!=='1'">
                        <el-radio-group v-model="searchForm.confirmOrder" @change="selectRadio2">
                            <el-radio-button :label="os.value" v-for="os in confirmOrderlist" :key="os.value">
                                {{ os.name }}
                            </el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item v-if="type !== 'trip'&&searchForm.orderStatus==='41'">
                        <el-radio-group v-model="searchForm.distributionMethod" @change="selectRadio3">
                            <el-radio-button :label="os.value" v-for="os in distributionMethodList" :key="os.value">
                                {{ os.name }}
                            </el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                </div>
                <el-form-item>
                    <el-select placeholder="订单来源"
                               clearable
                               :filterable="true"
                               v-model="searchForm.sourceType" size="small"
                               style="width: 160px">
                        <el-option
                            v-for="item in sourceTypeList"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select placeholder="类型" v-model="searchForm.productForm" size="small" clearable
                               style="width: 120px">
                        <el-option
                            v-for="item in productList"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input placeholder="订单编号" style="width: 120px" clearable
                              v-model="searchForm.orderNo"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input placeholder="源平台订单号" style="width: 140px"
                              v-model="searchForm.orgPlatOrderId" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input placeholder=" 服务商订单号" style="width: 140px"
                              v-model="searchForm.servProvOrderId" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-select placeholder="出发城市" filterable v-model="searchForm.productStartCityId" clearable
                               size="small" style="width: 120px">
                        <el-option
                            v-for="(item,index) in cityList"
                            :key="index"
                            :label="item.name"
                            :value="item.shortCode">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-autocomplete
                        v-model="searchForm.createOrginfoName" style="width: 220px"
                        :fetch-suggestions="querySearchAsync"
                        placeholder="下单机构"
                        @select="selectAgency">
                        <template slot-scope="{ item }">
                            <div class="list-pop-bot">
                                <!--<span>{{'[' + item.orgId + ']'}}</span>-->
                                <span>{{ item.merchantName }}</span>
                            </div>
                        </template>
                    </el-autocomplete>
                </el-form-item>
                <el-form-item>
                    <el-autocomplete
                        v-model="searchForm.createOrderUserName" style="width: 200px"
                        :fetch-suggestions="searchOrderUser"
                        placeholder="下单人姓名"
                        @select="selectOrderUser">
                        <template slot-scope="{ item }">
                            <div class="list-pop-bot">
                                <span>{{ item.loginName }}</span>
                            </div>
                        </template>
                    </el-autocomplete>
                </el-form-item>
                <el-form-item>
                    <el-input placeholder="下单人手机号" style="width: 120px" maxlength="11"
                              v-model="searchForm.createOrderUserMobile"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-date-picker size="small" style="width: 240px" v-model="searchForm.createOrderTime"
                                    type="daterange"
                                    range-separator="至"
                                    start-placeholder="下单起始日期"
                                    end-placeholder="下单截止日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-autocomplete
                        v-model="searchForm.tripUserName" style="width: 120px"
                        :fetch-suggestions="searchTripUserName"
                        placeholder="出行人姓名"
                        @select="selectTripUserName">
                        <template slot-scope="{ item }">
                            <div class="list-pop-bot">
                                <!--<span>{{'[' + item.orgId + ']'}}</span>-->
                                <span>{{ item.tripUserName }}</span>
                            </div>
                        </template>
                    </el-autocomplete>
                </el-form-item>
                <el-form-item>
                    <el-input placeholder="出行人手机号" style="width: 120px" maxlength="11"
                              v-model="searchForm.tripUserMobile"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-date-picker style="width: 240px" v-model="searchForm.tripTime"
                                    type="daterange"
                                    range-separator="至"
                                    start-placeholder="出行起始日期"
                                    end-placeholder="出行截止日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item v-if="type==='userCar'">
                    <el-autocomplete
                        v-model="searchForm.driverName" style="width: 120px"
                        :fetch-suggestions="searchDriverName"
                        placeholder="司导姓名"
                        @select="selectDriverName">
                        <template slot-scope="{ item }">
                            <div class="list-pop-bot">
                                <span>{{ item.driverName }}</span>
                            </div>
                        </template>
                    </el-autocomplete>
                </el-form-item>
                <el-form-item v-if="type==='userCar'">
                    <el-input placeholder="司导手机号" style="width: 120px" maxlength="11"
                              v-model="searchForm.driverMobile"></el-input>
                </el-form-item>
                <el-form-item v-if="type==='userCar'">
                    <el-autocomplete
                        v-model="searchForm.vehicleNo" style="width: 120px"
                        :fetch-suggestions="searchVehicleNo"
                        placeholder="车牌号"
                        @select="selectVehicleNo">
                        <template slot-scope="{ item }">
                            <div class="list-pop-bot">
                                <span>{{ item.carNumberPlate }}</span>
                            </div>
                        </template>
                    </el-autocomplete>
                </el-form-item>
                <el-form-item v-if="type==='trip'">
                    <el-input placeholder="产品编号" style="width: 120px" v-model="searchForm.productNo"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-autocomplete
                        v-model="searchForm.productName" style="width: 170px"
                        :fetch-suggestions="searchProductName"
                        placeholder="产品名称"
                        @select="selectProductName">
                        <template slot-scope="{ item }">
                            <div class="list-pop-bot">
                                <span>{{ item.productName }}</span>
                            </div>
                        </template>
                    </el-autocomplete>
                </el-form-item>
                <el-form-item v-if="type==='trip'">
                    <el-input placeholder="资源编号" style="width: 120px"
                              v-model="searchForm.resourceNo"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-autocomplete
                        v-model="searchForm.resourceName" style="width: 220px"
                        :fetch-suggestions="searchResourceName"
                        placeholder="资源名称"
                        @select="selectResourceName">
                        <template slot-scope="{ item }">
                            <div class="list-pop-bot">
                                <p>{{ item.resourceName }}</p>
                            </div>
                        </template>
                    </el-autocomplete>
                </el-form-item>
                <el-form-item>
                    <el-select placeholder="支付渠道"
                               v-model="searchForm.paymentChannels"
                               multiple
                               clearable
                               :filterable="true"
                               size="small"
                               style="width: 220px">
                        <el-option
                            v-for="item in payChannelList"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input placeholder="支付编号" style="width: 120px"
                              v-model="searchForm.payNo"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-select placeholder="退款状态" v-model="searchForm.cancelType" size="small" clearable
                               style="width: 120px">
                        <el-option label="无退款" value="0"></el-option>
                        <el-option label="有退款" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select v-model="searchForm.invoiceStatus" placeholder="发票状态" size="small" clearable
                               style="width: 120px">
                        <el-option label="未申请开票" value="0"></el-option>
                        <el-option label="待开票" value="1"></el-option>
                        <el-option label="已开票" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input placeholder="推广人手机号" style="width: 120px" maxlength="11"
                              v-model="searchForm.promotionMobile"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-select v-model="searchForm.financeConfirm" style="width: 150px" placeholder="财务确认">
                        <el-option label="全部" value="0"></el-option>
                        <el-option label="实收金额未确认" value="1"></el-option>
                        <el-option label="实付金额未确认" value="2"></el-option>
                        <el-option label="全部已确认" value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input placeholder="携程主订单号" style="width: 120px"
                              v-model="searchForm.ctripMasterOrderId"></el-input>
                </el-form-item>
                <el-form-item>
                    <SeatModel v-model="searchForm.vehicleTypes" :multiple="true" :clearable="true"
                               placeholder="选择车型"/>
                </el-form-item>
                <el-form-item>
                    <el-select clearable v-model="searchForm.isTripRebate" placeholder="分销订单">
                        <el-option :value="-1" label="全部">全部</el-option>
                        <el-option :value="1" label="分销订单"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="searchForm.sourceType===15">
                    <el-select v-model="searchForm.fliggyTag" placeholder="是否飞猪安心行" clearable>
                        <el-option :value="1" label="是">是</el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <el-row :gutter="20" class="screen-company-btn">
                <el-col :span="12">
                    <div class="screen-operate-sort">
                        <div style="display: inline-block">
                            <span>排序:</span>
                            <el-button type="text" style="color: #333;position: relative" @click="handleSort">下单时间
                                <i class="el-icon-caret-top i-top" :style="{color: orderSort==2?'#319BF7':'#999'}"></i>
                                <i class="el-icon-caret-bottom i-bottom"
                                   :style="{color: orderSort==1?'#319BF7':'#999'}"></i>
                            </el-button>
                            <el-button type="text" style="color: #333;position: relative" @click="handleTime">出行时间
                                <i class="el-icon-caret-top i-top" :style="{color: orderSort==4?'#319BF7':'#999'}"></i>
                                <i class="el-icon-caret-bottom i-bottom"
                                   :style="{color: orderSort==3?'#319BF7':'#999'}"></i>
                            </el-button>
                            <el-button type="text" v-if="searchForm.orderStatus==='31'"
                                       style="color: #333;position: relative" @click="handleSortLastPushDriverTime(1)">
                                <sapn class="color-error">最晚派单时间</sapn>
                                <i class="el-icon-caret-top i-top"
                                   :style="{color: orderSort==6?'#319BF7':'#999'}"></i>
                                <i class="el-icon-caret-bottom i-bottom"
                                   :style="{color: orderSort==5?'#319BF7':'#999'}"></i>
                            </el-button>
                        </div>

                    </div>

                </el-col>
                <el-col :span="12">
                    <div class="screen-operate-btn">
                        <el-button @click="resetBtn()" class="btn-border-319 reset-but">重置</el-button>
                        <el-button @click="orderExport(1)" class="btn-border-319"
                                   v-if="showExport&&userInfo.userType==0">财务导出
                        </el-button>
                        <el-button @click="orderExport(2)" class="btn-border-319">导出</el-button>
                        <BtnTripOrderImport :type="type" @success="resetBtn()">
                            <el-button class="btn-border-319" style="margin:0 5px">导入</el-button>
                        </BtnTripOrderImport>
                        <el-button type="primary" @click="serach()">搜索</el-button>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script type="text/ecmascript-6">
import orderServer from '../../../../urls/orderUrls';
import {productFormList} from "@/data/index";
import dataInfo from "@/data/orderData/data";
import SeatModel from '@/components/seatModel'
import {readBlob} from "@/common/js/Utils";
import BtnTripOrderImport from '../btnTripOrderImport/index'
import dayjs from "dayjs";

export default {
    name: 'searchForm',
    components: {
        SeatModel,
        BtnTripOrderImport
    },
    //定义模版数据
    data() {
        return {
            timeIntervals: [
                {
                    label: '近30天订单',
                    value: 30
                },
                {
                    label: '近60天订单',
                    value: 60
                },
                {
                    label: '近90天订单',
                    value: 90
                },
                {
                    label: '全部订单',
                    value: 365
                }
            ],
            searchForm: {
                queryDay: 30,
                orderStatus: '0',
                resourceSupplierId: '',
                cancelStatus: '0',
                confirmOrder: '0',
                distributionMethod: '0',
                sortField: 'create_time',
                sortOrder: 'DESC',
                vehicleType: null,
                vehicleTypes: [],
                orderNo: undefined,
                isTripRebate: undefined
            },
            orderStatusList: [
                {name: '全部', value: '0', num: 0, type: ""},
                {name: '待确认订单', value: '22', num: 0, type: ""},
                {name: '待分配司导', value: '31', num: 0, type: ""},
                {name: '抢单中', value: '32', num: 0, type: "trip"},
                {name: '待出行', value: '41', num: 0, type: ""},
                {name: '进行中', value: '51', num: 0, type: "trip"},
                {name: '已完成', value: '61', num: 0, type: ""},
                {name: '取消订单', value: '71', num: 0, type: ""},
                {name: '变更订单', value: '81', num: 0, type: ""}
            ],
            cacelStatusList: [
                {name: '用户申请取消', value: '1', num: 0},
                {name: '待供应商确认取消', value: '3', num: 0},
                {name: '已取消', value: '2', num: 0}
            ],
            confirmOrderlist: [
                {name: '全部', value: '0'},
                {name: '新订待分配', value: '21'},
                {name: '待供应商确认', value: '22'},
                // {name: '供应商拒绝', value: '23'},
            ],
            distributionMethodList: [
                {name: '全部', value: '0'},
                {name: '司导抢单，待出行', value: '2'},
                {name: '直接派单，待出行', value: '1'},
            ],
            isShowMore: false,
            modifyStatusCount: 0,
            payTypeList: dataInfo.payTypeList,
            sourceTypeList: dataInfo.sourceTypeList,
            productFormList: productFormList,
            productOperatorId: '',
            resourceSupplierId: '',
            // cityList: [],
            supplierList: [],
            mechanismList: [],
            orderSort: true
        }
    },
    props: {
        cityList: {
            type: Array,
            default: function () {
                return []
            }
        },
        operatorList: {
            type: Array,
            default: function () {
                return []
            }
        },
        payChannelList: {
            type: Array,
            default: function () {
                return []
            }
        },
        type: {
            type: String,
            default: 'userCar'
        }
    },
    //计算属性
    computed: {
        showExport() {
            return this.$store.state.loginUserInfo.loginuserId !== '8a9e34a16a20eba7016a448f1c1f005c'
        },
        userInfo() {
            return this.$store.state.loginUserInfo
        },
        productList() {
            const list = Object.assign([], productFormList);
            const arr = [4, 5, 6, 11, 16, 17, 18, 21];
            let list1 = new Set([]);
            let list2 = new Set(list);
            let productList = [];
            list.map(item => {
                arr.map(a => {
                    if (item.value === a) {
                        list1.add(item);
                        list2.delete(item)
                    }
                })
            })
            if (this.type === "trip") productList = list1;
            else productList = list2;
            return productList;
        },
        orderStatusListData() {
            return this.orderStatusList.filter((item) => item.type !== this.type)
        }
    },
    //主件被加载完成
    mounted: function () {
        if (this.userInfo.userType == 0)
            this.getOrderNum();
        else
            this.getOrderNum(this.userInfo.refId);
        if (this.userInfo.userType == 2) this.selectOperator(this.userInfo.refId);
        if (this.$route.query.orderNo)
            this.searchForm.orderNo = this.$route.query.orderNo;
    },
    //定义事件方法
    methods: {
        //用户切换展示数据
        handleTimeInterval(val) {
            this.getOrderNum(null, null, val)
            this.serach();
        },
        serach() {
            let form = JSON.parse(JSON.stringify(this.searchForm));
            if (form.financeConfirm) form.financeConfirm = parseInt(form.financeConfirm)
            if (!form.vehicleType)
                delete form.vehicleType;
            if (form.isTripRebate === -1)
                delete form.isTripRebate
            this.$emit('searchOrder', form)
        },
        resetBtn() {
            this.searchForm = {
                queryDay: 30,
                orderStatus: '0',
                resourceSupplierId: '',
                cancelStatus: '0',
                confirmOrder: '0',
                distributionMethod: '0',
                sortField: 'create_time',
                sortOrder: 'DESC',
                vehicleType: null,
                vehicleTypes: [],
                isTripRebate: undefined
            };
            this.$emit('searchOrder', this.searchForm)
        },
        resetForm(form) {
            this.searchForm = {
                queryDay: 30,
                orderStatus: '0',
                resourceSupplierId: '',
                cancelStatus: '0',
                confirmOrder: '0',
                distributionMethod: '0',
                sortField: 'create_time',
                sortOrder: 'DESC',
                vehicleType: null,
                vehicleTypes: [],
                ...form
            };
        },
        selectRadio(e) {
            this.searchForm.cancelStatus = 0;
            delete this.searchForm.cancelStatusList;
            this.searchForm.distributionMethod = 0;
            delete this.searchForm.distributionMethodList;
            this.searchForm.sortField = 'create_time';
            this.searchForm.sortOrder = 'DESC';
            this.orderSort = 1;
            switch (e.name) {
                case '0':
                    this.searchForm.orderStatusList = [];
                    break;
                case '22':
                    //供应商只能查看待确认的订单
                    this.searchForm.orderStatusList = this.userInfo.refType === '1' ? ['22'] : ['21', '22', '23'];
                    break;
                case '51':
                    this.searchForm.orderStatusList = ['42', '43', '51'];
                    break;
                case '71':
                    this.searchForm.orderStatusList = [];
                    this.searchForm.cancelStatusList = ['1', '3', '2'];
                    break;
                case '81':
                    this.searchForm.orderStatusList = [];
                    break;
                default:
                    this.searchForm.orderStatusList = [e.name];
                    break;
            }
            if (e.name === '81') this.searchForm.modifyStatus = "1";
            else delete this.searchForm.modifyStatus
            this.$emit('searchOrder', this.searchForm);
        },
        selectRadio1(val) {
            this.searchForm.cancelStatusList = [val];
            if (val === '0') this.searchForm.cancelStatusList = ['1', '2', '3'];
            delete this.searchForm.modifyStatus;
            this.$emit('searchOrder', this.searchForm);
        },
        selectRadio2(val) {
            this.searchForm.orderStatusList = [val];
            if (val === '0') this.searchForm.orderStatusList = ['21', '22', '23'];
            this.$emit('searchOrder', this.searchForm);
        },
        selectRadio3(val) {
            this.searchForm.distributionMethodList = [val];
            if (val === '0') this.searchForm.distributionMethodList = [];
            this.$emit('searchOrder', this.searchForm);
        },
        async orderExport(type) {
            this.loadingShow();
            let form = JSON.parse(JSON.stringify(this.searchForm));
            if (form.tripTime) {
                form.tripStartTime = +new Date(form.tripTime[0]);
                form.tripEndTime = +new Date(form.tripTime[1]);
                delete form.tripTime;
            }
            if (form.createOrderTime) {
                form.createOrderStartTime = +new Date(form.createOrderTime[0]);
                form.createOrderEndTime = dayjs(form.createOrderTime[1]).endOf('d').valueOf();
                delete form.createOrderTime;
            }
            form.orderType = this.type === 'trip' ? '2' : '1';
            delete form.orderStatus;
            delete form.cancelStatus;
            // delete form.modifyStatus;
            delete form.confirmOrder;
            delete form.distributionMethod;
            let url = '/galaxyOrder/bmsExportOrder';
            if (type == 1) {
                url = '/galaxyOrder/financeExportOrder';
            }
            try {
                let res = await this.http(url, form, 'POST', true, 'blob');
                const blobData = await readBlob(res);
                try {
                    const data = JSON.parse(blobData);
                    if (data && data.errors) {
                        this.loadingHide();
                        return this.$message.error(data.errors[0].message)
                    }
                } catch (err) {
                    console.log(err);
                }
                let d = new Date().getTime();
                let a = document.createElement('a');
                if (type == 1) {
                    a.download = '财务订单导出表' + d + '.xls';
                }
                if (type == 2) {
                    a.download = '订单导出表' + d + '.xls';
                }

                a.href = window.URL.createObjectURL(res);
                a.click();

                // }
                this.loadingHide();
            } catch (e) {
                this.loadingHide();
                this.messageError('下载失败')
            }
        },
        async getOrderNum(id, type, queryDay) {
            let form = {
                queryDay: this.searchForm.queryDay
            };
            if (id && type == 1) form.operatorId = id;
            if (id && type == 2) form.supplierId = id;
            if (queryDay)
                form = {
                    ...this.searchStatusForm,
                    queryDay
                };
            this.searchStatusForm = form;
            let res = await orderServer.getOrderStatusCount(this, form, this.type === "trip" ? 1 : 0);
            if (res.success && res.data) {
                let count = 0, cancelCount = 0;
                if (res.data.orderStatus && res.data.orderStatus.length > 0) {
                    this.orderStatusList.forEach(item1 => {
                        res.data.orderStatus.forEach(item => {
                            if (item1.value == item.orderStatus) item1.num = item.count;
                            switch (item1.value) {
                                case '22':
                                    break;
                            }
                            if (item1.value === '22') {
                                if (item.orderStatus == 21) count += item.count;
                                if (item.orderStatus == 22) count += item.count;
                                if (item.orderStatus == 23) count += item.count;
                                item1.num = count;
                            }
                        })
                        if (res.data.cancelStatus && res.data.cancelStatus.length > 0) {
                            res.data.cancelStatus.forEach(item => {
                                if (item1.value === '71') {
                                    if (item.cancelStatus == 1) cancelCount += item.count;
                                    if (item.cancelStatus == 2) cancelCount += item.count;
                                    if (item.cancelStatus == 3) cancelCount += item.count;
                                    item1.num = cancelCount;
                                }
                            })
                        }
                        if (item1.value === '81') {
                            item1.num = res.data.modifyStatusCount || 0;
                        }
                    })
                }
            }
        },
        async getSupplierInfoByOperatorId(id) {
            this.supplierList = [];
            const ret = await orderServer.getSupplierInfoByOperatorId(this, {operatorId: id})
            if (ret.success) {
                this.supplierList = ret.data;
            }
        },
        async selectOperator(id) {
            this.getSupplierInfoByOperatorId(id);
            this.searchForm.resourceSupplierId = '';
            this.getOrderNum(id, 1);
            this.serach();
        },
        async selectSupplier(id) {
            this.getOrderNum(id, 2);
            this.serach();
        },
        async selectAgency(val) {
            this.searchForm.createOrginfoName = val.merchantName;
        },
        selectOrderUser(val) {
            // this.searchForm.loginName = val.loginName;
            this.searchForm.createOrderUserName = val.loginName;
        },
        selectTripUserName(val) {
            this.searchForm.tripUserName = val.tripUserName;
        },
        selectDriverName(val) {
            this.searchForm.driverName = val.driverName;
        },
        selectVehicleNo(val) {
            this.searchForm.vehicleNo = val.carNumberPlate;
        },
        selectProductName(val) {
            this.searchForm.productName = val.productName;
        },
        selectResourceName(val) {
            this.searchForm.resourceName = val.resourceName;
        },
        async querySearchAsync(query, cb) {
            if (query && query.length > 1) {
                this.querySearch(query, cb, 1)
            }
        },
        searchOrderUser(query, cb) {
            if (query && query.length > 0) {
                this.querySearch(query, cb, 2)
            }
        },
        searchTripUserName(query, cb) {
            if (query && query.length > 0) {
                this.querySearch(query, cb, 3)
            }
        },
        searchDriverName(query, cb) {
            if (query && query.length > 0) {
                this.querySearch(query, cb, 4)
            }
        },
        searchVehicleNo(query, cb) {
            if (query && query.length > 0) {
                this.querySearch(query, cb, 5)
            }
        },//searchVehicleNo
        searchProductName(query, cb) {
            if (query && query.length > 0) {
                this.querySearch(query, cb, 6)
            }
        },
        searchResourceName(query, cb) {
            if (query && query.length > 0) {
                this.querySearch(query, cb, 7)
            }
        },
        async querySearch(query, cb, index) {
            let url = '';
            let form = {};
            let postType = 'GET';
            switch (index) {
                case 1:
                    url = '/galaxyMerchant/merchantInfo';
                    form = {
                        merchantName: query
                    }
                    break;
                case 2:
                    url = `/galaxyLoginuserApi/doGalaxySelectLoginuserByName/${query}`;
                    break;
                case 3:
                    url = `/galaxyOrder/selectTripUserInfoByName/${query}`;
                    break;
                case 4:
                    url = "/galaxyDriverApproveApi/doGalaxySelectDriverInfoByName";
                    form = {
                        driverName: query
                    }
                    postType = 'POST';
                    break;
                case 5:
                    url = `/galaxyDriverApproveApi/doGalaxyGetCarInfoByNumberPlate/${query}`;
                    break;
                case 6:
                    url = '/galaxyProduct/selectProductByName';
                    form = {
                        productName: query,
                        productTypes: this.type === 'trip' ? ['3'] : ['1', '2']
                    }
                    postType = 'POST';
                    break;
                case 7:
                    url = '/galaxyResource/selectProductResourceByName';
                    form = {
                        resourceName: query,
                        productTypes: this.type === 'trip' ? ['3'] : ['1', '2']
                    }
                    postType = 'POST';
                    break;
            }
            let res = await this.http(url, form, postType);
            if (res.success && res.data) {
                // let mechanismList = res.data;
                let mechanismList = query ? res.data.filter(this.createFilter(query, index)) : res.data;
                cb(mechanismList);
            } else {
                this.messageError('系统出错，请稍后!');
            }
        },
        createFilter(itemStr, index) {
            return (restaurant) => {
                let result = false;
                switch (index) {
                    case 1:
                        result = (restaurant.merchantName.indexOf(itemStr) >= 0);
                        break;
                    case 2:
                        result = (restaurant.loginName.indexOf(itemStr) >= 0);
                        break;
                    case 3:
                        result = (restaurant.tripUserName.indexOf(itemStr) >= 0);
                        break;
                    case 4:
                        result = (restaurant.driverName.indexOf(itemStr) >= 0);
                        break;
                    case 5:
                        result = (restaurant.carNumberPlate.indexOf(itemStr) >= 0);
                        break;
                    case 6:
                        result = (restaurant.productName.indexOf(itemStr) >= 0);
                        break;
                    case 7:
                        result = (restaurant.resourceName.indexOf(itemStr) >= 0);
                        break;
                }
                return result;
            };
        },
        handleSort() {
            // this.orderSort = this.orderSort;
            if (this.orderSort != 1) this.orderSort = 1;
            else this.orderSort = 2;
            this.searchForm.sortField = 'create_time';
            this.searchForm.sortOrder = this.orderSort === 1 ? 'DESC' : 'ASC';
            this.$emit('searchOrder', this.searchForm)
        },
        handleTime() {
            if (this.orderSort != 3) this.orderSort = 3;
            else this.orderSort = 4;
            this.searchForm.sortField = 'reserve_time';
            this.searchForm.sortOrder = this.orderSort === 3 ? 'DESC' : 'ASC';
            this.$emit('searchOrder', this.searchForm)
        },
        handleSortLastPushDriverTime() {
            if (this.orderSort != 5) this.orderSort = 5;
            else this.orderSort = 6;
            this.searchForm.sortField = 'last_push_driver_time';
            this.searchForm.sortOrder = this.orderSort === 5 ? 'DESC' : 'ASC';
            this.$emit('searchOrder', this.searchForm)
        },
        handleShowSupplierList(bool) {
            if (bool && this.userInfo.userType === '0' && !this.searchForm.productOperatorId) {
                this.getSupplierInfoByOperatorId()
            }
        }
    },
    //监听模版变量
    watch: {
        '$route'() {
            console.log(this.$route.path, 323232);
            if (this.$route.query.orderNo && ['/userCarList'].includes(this.$route.path)) {
                this.resetForm({
                    orderNo: this.$route.query.orderNo
                })
                this.serach();
            }
        }
    }

}
</script>
