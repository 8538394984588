<style lang="scss">
.order-list-table {
    tr {
        background: #fff;
        min-height: 30px;
        font-size: 12px;

        td {
            padding: 8px 5px;
            border: 1px solid #F0F8FF;
            min-width: 70px;
            max-width: 120px;
            text-align: center;
            line-height: 21px;
            word-wrap: break-word;
        }

    }

    .font-title-w6 {
        font-weight: 600;
    }
}

.pop-content {
    padding: 10px;
    font-size: 12px;
    color: #000000;

    .el-form-item__label {
        font-size: 12px;
        font-weight: 700;
        padding: 0;
        line-height: 38px;
    }
}

.btn-border-319 {
    border-color: #319BF7;
    color: #319BF7;
    /*font-size: 14px;*/
}

.btn-border-e6a23c {
    /*border-color: #e6a23c;*/
    color: #004789;;
    font-size: 14px;

    &:hover {
        background: #004789;;
        /*border-color: #004789;;*/
        color: #fff;
    }
}

.btn-border-FF3B30 {
    border-color: #FF3B30;
    color: #FF3B30;
    /*font-size: 14px;*/
}

.btn-left-list {
    .el-button {
        position: relative;
        font-size: 14px;

        &:before {
            content: "";
            width: 0;
            height: 0;
            border-width: 8px 8px 0 8px;
            border-style: solid;
            border-color: #409EFF transparent transparent transparent;
            position: absolute;
            bottom: -8px;
            left: 50%;
            margin-left: -8px;
            opacity: 0;
        }

        margin-right: 5px;
    }

    .sect_btn_select {
        background: #409EFF;
        border-color: #409EFF;
        color: #fff;

        &.el-button {
            &:before {
                opacity: 1;
            }

            &:hover {
                background: #409EFF !important;
                border-color: #409EFF;
                color: #fff;
            }
        }
    }

}

.btn-list {
    /*text-align: right;*/
    /*float: left;*/
    /*margin-top: 10px;*/
    .el-button {
        position: relative;
        margin-left: 5px;
        font-size: 14px;

        &:before {
            content: "";
            width: 0;
            height: 0;
            border-width: 8px 8px 0 8px;
            border-style: solid;
            border-color: #004789 transparent transparent transparent;
            position: absolute;
            bottom: -8px;
            left: 50%;
            margin-left: -8px;
            opacity: 0;
        }

    }

    .sect_btn_select {
        background: #004789;
        /*border-color: #e6a23c;*/
        color: #fff;

        &.el-button {
            &:before {
                opacity: 1;
            }

            &:hover {
                background: #004789 !important;
                /*border-color: #e6a23c;*/
                color: #fff;
            }
        }
    }
}

.reset-but.el-button:focus {
    border-color: #319BF7;
    color: #319BF7;
    background: #fff;
}

.trip-info-dialog .el-dialog__body {
    padding: 0 20px;
}

.trip-info-dialog-header {
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
}

.order-change {
    .el-input-group__append, .el-input-group__prepend {
        padding: 0 15px;
        background-color: #fff;
        color: #333;
    }

    .el-input__inner {
        padding-left: 15px;
    }

}
</style>
<style lang="scss" scoped>
.order-list {
    .amap {
        width: 100%;
        height: 100%;
        min-height: 300px;
        overflow: hidden;
        margin: 0;
    }

    .el-button {
        padding: 8px 20px;
    }

    .order-list-main {
        /*background: #ffffff;*/
        /*padding: 10px 20px 0;*/
        margin-top: 10px;
        margin-bottom: 15px;
        /*width: 1088px;*/
        /*display: inline-block;*/

        .order-list-detail {
            /*width: 1068px;*/
            border: 1px solid #87C3FF;
            margin-top: 10px;
            /*padding: 10px;*/
            background: #f8f8f8;
            margin-bottom: 15px;

            .order-detail {
                /*width: 100%;*/
                /*height: 100px;*/
                padding: 10px;
                background: #F0F8FF;
                font-size: 12px;
                position: relative;

                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    left: 10px;
                    right: 10px;
                    bottom: 0;
                    height: 1px;
                    background: #fff;
                }
            }

            .refund-record {
                /*width: 100%;*/
                min-height: 88px;
                background: #F0F8FF;
                font-size: 12px;
                padding: 10px;

                .refund-record-label {
                    font-weight: 700;
                    /*height: 35px;*/
                    line-height: 20px;
                    margin-bottom: 8px;
                }

                .refund-record-table {
                    width: 100%;
                    text-align: center;
                }
            }
        }

        .serveice-note {
            /*width: 1068px;*/
            min-height: 90px;
            margin-top: 10px;
            background: #F0F8FF;
            padding: 10px;
            border: 1px solid #87C3FF;
            position: relative;
            margin-bottom: 15px;

            .serveice-note-input {
                .el-form-item__error {
                    padding-top: 0;
                }

            }
        }

        .public-tip_input {
            position: relative;
            margin-bottom: 10px !important;
        }

        .driver-info-res {
            position: absolute;
            left: 0;
            right: 0;
            top: 33px;
            max-height: 150px;
            z-index: -10;
            opacity: 0;
            font-size: 14px;
            padding-left: 15px;
            min-width: 240px;
            background: #fff;
            border: 1px solid #E5E5E5;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.10);
            border-radius: 4px;
            transition: opacity 200ms;
            -webkit-transition: opacity 2s;
            overflow: hidden;
            overflow-y: scroll;
            white-space: nowrap;

            &.info-res_z {
                z-index: 10;
                opacity: 1;
            }
        }

        .driver-confirm_prompt {
            color: #FF3B30;
            font-size: 14px;
            line-height: 1;
        }

        .customer-service-record {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .refund-reason-textarea {
            width: 355px;
            height: 119px;
        }

    }

    .word-desc {
        line-height: 40px;
        padding-right: 20px;
        font-size: 14px;
        color: #909399;
    }
}

.order-list /deep/ {
    .el-form-item__error {
        padding-top: 0;
    }

    .order-search-form {
        .el-form-item--mini.el-form-item, .el-form-item--small.el-form-item {
            margin-bottom: 10px;
        }

        .el-form--inline .el-form-item {
            margin-right: 4px;
        }
    }

    .their-price {
        .el-input__prefix {
            color: #333;
            font-size: 14px;
            margin-top: -2px;
        }
    }

    .serveice-note-input {
        .el-form-item__error {
            padding-top: 0;
        }
    }

    .el-form-item {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .refund-reason-textarea {
        .el-textarea__inner {
            height: 100%;
        }
    }
}
</style>
<template>
    <div>
        <el-main class="com-eTrip-section">
            <div class="eTrip-section-cont order-list">
                <slot>
                    <search-form ref="searchForm"
                                 :city-list="cityList"
                                 :operator-list="operatorList"
                                 :type="type"
                                 @searchOrder="search"
                                 :pay-channel-list="payChannelList">
                    </search-form>
                </slot>
                <div class="order-list-main" v-for="(item, index) in orderManageList" :key="item.orderId">
                    <order-main :order-info="item" :user-info="userInfo" :order-info-index="index"
                                :type="type"
                                @updateOrderList="updateOrderList"
                                @updateCTripCost="updateCTripCost"></order-main>
                    <el-row class="clear" style="background: #fff;display: flex;" v-if="item.orderStatus>11">
                        <div style="flex: 1" class="btn-left-list">
                            <el-button v-if="userInfo.userType!=1" type="text"
                                       :class="{'sect_btn_select': item.isOrderChange&&item.showIndex==4}" size="small"
                                       @click="showChange(index, 4)">订单详情
                            </el-button>
                            <el-popover v-if="userInfo.userType!=1"
                                        placement="top-start"
                                        width="400"
                                        trigger="click">
                                <div style="padding: 10px;font-size: 12px;color: #000000;">
                                    <p style="font-size: 24px;color: #000B15;">发票信息</p>
                                    <p style="color: #319BF7;line-height: 40px;">
                                        {{
                                            item.invoiceStatus == 1 ? '待开票' : (item.invoiceStatus == 2 ? '已开票' :
                                                "未申请开票")
                                        }}</p>
                                    <div v-if="item.invoiceStatus&&item.invoiceStatus!=0&&item.orderInvoiceDto">
                                        <p>
                                            开票方式：{{
                                                item.orderInvoiceDto.invoiceModel == 1 ? '电子发票' : '纸质发票'
                                            }}</p>
                                        <p>
                                            开票内容：{{ item.orderInvoiceDto.invoiceContent }}</p>
                                        <p style="font-weight:700">
                                            {{
                                                item.orderInvoiceDto.invoiceType == 1 ? '个人' :
                                                    (item.orderInvoiceDto.invoiceType == 2 ? '公司普通' : "公司专用")
                                            }}：</p>
                                        <p>抬头：{{ item.orderInvoiceDto.invoiceHead }}</p>
                                        <p v-if="item.orderInvoiceDto.invoiceType!=1">
                                            税号：{{ item.orderInvoiceDto.invoiceTax }}</p>
                                        <div v-if="item.orderInvoiceDto.invoiceModel==0">
                                            <p v-if="item.orderInvoiceDto.invoiceType==3">
                                                公司地址：{{ item.orderInvoiceDto.companyAddress }}</p>
                                            <p v-if="item.orderInvoiceDto.invoiceType==3">
                                                公司电话：{{ item.orderInvoiceDto.companyPhone }}</p>
                                            <p v-if="item.orderInvoiceDto.invoiceType==3">
                                                银行名称：{{ item.orderInvoiceDto.bankName }}</p>
                                            <p v-if="item.orderInvoiceDto.invoiceType==3">
                                                银行账号：{{ item.orderInvoiceDto.bankAccount }}</p>
                                        </div>
                                    </div>

                                </div>
                                <div style="padding: 10px;font-size: 12px;color: #000000;border-top: 1px solid #F4F4F4;"
                                     v-if="item.invoiceStatus&&item.invoiceStatus!=0&&item.orderInvoiceDto&&item.orderInvoiceDto.invoiceModel==0">
                                    <p style="font-weight:700">收件地址：</p>
                                    <p>{{ item.orderInvoiceDto.recipetName }}，{{ item.orderInvoiceDto.mobile }}</p>
                                    <p>上海，上海市，普陀区怒江北路561弄6号楼2楼</p>
                                    <p>{{ item.orderInvoiceDto.zipCode }}</p>
                                </div>
                                <div style="padding: 10px;font-size: 12px;color: #000000;border-top: 1px solid #F4F4F4;"
                                     v-if="item.invoiceStatus&&item.invoiceStatus!=0&&item.orderInvoiceDto&&item.orderInvoiceDto.invoiceModel==1">
                                    <p>推送手机：{{ item.orderInvoiceDto.mobile }}</p>
                                    <p>推送邮箱：{{ item.orderInvoiceDto.email || '-' }}</p>
                                </div>
                                <div style="padding: 10px;font-size: 12px;color: #000000;border-top: 1px solid #F4F4F4;"
                                     v-if="item.invoiceStatus&&item.invoiceStatus!=0&&item.orderInvoiceDto&&item.orderInvoiceDto.invoiceModel==1">
                                    <p>开票金额：{{ item.orderInvoiceDto.invoiceAmount | currency }}</p>
                                    <p>发票流水号：{{ item.orderInvoiceDto.invoiceSerialNum }}</p>
                                    <p>开票时间：{{
                                            item.orderInvoiceDto.updateTime | dateCus('yyyy-MM-dd hh:mm:ss')
                                        }}</p>
                                    <p>开票代码：{{ item.orderInvoiceDto.invoiceCode }}</p>
                                    <p>发票号码：{{ item.orderInvoiceDto.invoiceNumber }}</p>
                                    <p>发票PDF：<span style="cursor: pointer;color:#319BF7"
                                                     @click="openPDF(item.orderInvoiceDto.invoiceFileUrl)">查看</span>
                                    </p>
                                </div>
                                <div style="padding: 10px;font-size: 12px;color: #000000;border-top: 1px solid #F4F4F4;"
                                     v-if="item.invoiceStatus&&item.invoiceStatus!=0&&item.orderInvoiceDto&&item.orderInvoiceDto.invoiceModel==0">
                                    <p>开票金额：{{ item.orderInvoiceDto.invoiceAmount | currency }}</p>
                                    <p>发票号码：{{ item.orderInvoiceDto.invoiceNumber || '-' }}</p>
                                    <p>快递公司：{{ item.orderInvoiceDto.expressCompany || '-' }}</p>
                                    <p>发票单号：{{ item.orderInvoiceDto.expressNumber || '-' }}</p>
                                </div>
                                <el-button slot="reference" type="text" size="small">发票信息</el-button>
                            </el-popover>
                            <el-button size="small" type="text"
                                       :class="{'sect_btn_select': item.isOrderChange&&item.showIndex==5}"
                                       @click="showChange(index, 5, item.orderId)">
                                客服备注
                            </el-button>
                            <el-button size="small" type="text"
                                       :class="{'sect_btn_select': item.isOrderChange&&item.showIndex==6}"
                                       @click="showChange(index, 6)" v-if="item.resourceUseCar==1">
                                行程信息
                            </el-button>
                            <passing-point :order-info="item"
                                           v-if="item.orderViaPointDtos&&item.orderViaPointDtos.length>0"
                                           :list="item.orderViaPointDtos"></passing-point>
                            <el-button size="small" type="text"
                                       :class="{'sect_btn_select': item.isOrderChange&&item.showIndex==7}"
                                       @click="showChange(index, 7)">
                                订单日志
                            </el-button>

                            <el-button size="small" type="text"
                                       :class="{'sect_btn_select': item.isOrderChange&&item.showIndex==60}"
                                       @click="showChange(index, 60)">
                                评价记录
                            </el-button>
                            <!--查看行程录音-->
                            <recording-itinerary :info="item" v-if="type==='userCar'"></recording-itinerary>
<!--                            <el-button size="small" type="text"-->
<!--                                       :class="{'sect_btn_select': item.isOrderChange&&item.showIndex==='eventDetail'}"-->
<!--                                       @click="showChange(index, 'eventDetail')">-->
<!--                                事件信息-->
<!--                            </el-button>-->
<!--                            <el-button size="small" type="text"-->
<!--                                       v-if="item.sourceType===15"-->
<!--                                       :class="{'sect_btn_select': item.isOrderChange&&item.showIndex==='fliggyWorkOrder'}"-->
<!--                                       @click="showChange(index, 'fliggyWorkOrder')">-->
<!--                                飞猪工单-->
<!--                            </el-button>-->
                        </div>
                        <div style="flex: 1" class="btn-list" v-if="item.sourceType!=7">
                            <el-popover
                                v-if="[22,31,41,42,43,51].includes(item.orderStatus)"
                                v-model="item.restoreOrderShow"
                                placement="top-start"
                                width="400"
                                trigger="click"
                                @show="popoverShow('restoreOrderShow', index)">
                                <div style="padding: 10px;font-size: 12px;color: #000000;">
                                    <p style="font-size: 24px;font-weight:700;margin-bottom: 10px">派单重置</p>
                                    <p>是否确认将当前订单置为新订待分配?</p>
                                    <div style="text-align: right; margin-top: 20px">
                                        <el-button class="el-button_pub" @click="cancelClose(index)">取消</el-button>
                                        <el-button class="el-button_pub" type="primary" @click="restoreNewOrder(index)"
                                                   :loading="isBtn">
                                            确定
                                        </el-button>
                                    </div>
                                </div>
                                <el-button slot="reference" size="small" type="text" style="color: #004789">派单重置
                                </el-button>
                            </el-popover>
                            <el-popover v-model="item.refuseShow" @show="popoverShow('refuseShow', index)"
                                        v-if="((item.orderStatus==23||item.orderStatus==21)&&userInfo.userType!=1)||(item.orderStatus==22&&userInfo.userType==1)"
                                        placement="top-start"
                                        width="400"
                                        trigger="click">
                                <div style="padding: 10px;font-size: 12px;color: #000000;">
                                    <p style="font-size: 24px;font-weight:700;margin-bottom: 10px">拒绝订单</p>
                                    <p v-if="item.productType!=7">该产品资源无库存。将拒绝用户订单，确认继续? </p>
                                    <el-form :ref="'refusalOrderForm'+index"
                                             v-if="item.productType==7"
                                             :model="refusalOrderForm"
                                             label-width="120px">
                                        <el-form-item label="拒绝理由:"
                                                      prop="reason"
                                                      :rules="[{ required: true, message: '请输入拒绝理由'}]">
                                            <el-input type="textarea" :rows="2"
                                                      placeholder="请输入拒绝理由"
                                                      v-model="refusalOrderForm.reason">
                                            </el-input>
                                        </el-form-item>
                                    </el-form>
                                    <div style="text-align: right;margin-top: 20px">
                                        <el-button @click="cancelClose(index)" style="padding: 8px 20px">取消
                                        </el-button>
                                        <el-button type="primary" @click="operatorRefuse(index)" :loading="isBtn"
                                                   style="padding: 8px 20px">确定
                                        </el-button>
                                    </div>
                                </div>
                                <el-button slot="reference" size="small"
                                           v-if="item.sourceType != 2&&![12,13,15,18,19,23,24,25,28].includes(item.sourceType)"
                                           type="text"
                                           style="color: #004789">拒绝订单
                                </el-button>
                                <!--                                <el-button slot="reference" size="small"-->
                                <!--                                           v-if="sourceTypeList[item.sourceType - 1].value != 2" type="text"-->
                                <!--                                           style="color: #004789">拒绝订单-->
                                <!--                                </el-button>-->
                            </el-popover>
                            <el-popover v-if="item.orderStatus==22&&(item.resourceUseCar==0||userInfo.userType==1)"
                                        v-model="item.confirmShow"
                                        placement="top-start"
                                        width="400"
                                        trigger="click"
                                        @show="popoverShow('confirmShow', index)">
                                <div style="padding: 10px;font-size: 12px;color: #000000;">
                                    <p style="font-size: 24px;font-weight:700;margin-bottom: 10px">确认订单</p>
                                    <p>即将接单，确认继续?</p>
                                    <div style="text-align: right; margin-top: 20px">
                                        <el-button class="el-button_pub" @click="cancelClose(index)">取消</el-button>
                                        <el-button class="el-button_pub" type="primary" @click="operatorConfirm(index)"
                                                   :loading="isBtn">
                                            确定
                                        </el-button>
                                    </div>
                                </div>
                                <el-button slot="reference" size="small" type="text" style="color: #004789">确认订单
                                </el-button>
                            </el-popover>
                            <el-popover v-if="item.orderStatus==41"
                                        v-model="item.shortMessageShow"
                                        placement="top-start"
                                        width="400"
                                        trigger="click"
                                        @show="popoverShow('shortMessageShow', index)">
                                <div style="padding: 10px;font-size: 12px;color: #000000;">
                                    <p style="font-size: 24px;font-weight:700;margin-bottom: 10px">重发短信</p>
                                    <p>重发确认短信，确认继续?</p>
                                    <div style="text-align: right; margin-top: 20px">
                                        <el-button class="el-button_pub" @click="cancelClose(index)">取消</el-button>
                                        <el-button class="el-button_pub" type="primary" :loading="isBtn"
                                                   @click="operatorShortMess(index)">
                                            确定
                                        </el-button>
                                    </div>
                                </div>
                                <el-button slot="reference" size="small" type="text" style="color: #004789">
                                    重发确认短信
                                </el-button>
                            </el-popover>
                            <el-button
                                v-if="((item.orderStatus==31||item.orderStatus==41)&&![12,13,15,18,19,23,24,25,28].includes(item.sourceType))||(item.orderStatus==21&&item.sourceType==11)"
                                size="small" :class="{'sect_btn_select': item.isOrderChange&&item.showIndex==1}"
                                class="btn-border-e6a23c" type="text"
                                @click="showChange(index, 1)">取消订单
                            </el-button>
                            <el-button :class="{'sect_btn_select': item.isOrderChange&&item.showIndex==2}"
                                       v-if="(item.orderStatus==31||item.orderStatus==41||
                                       item.orderStatus==51||item.orderStatus==61)&&
                                       userInfo.userType!=1&&
                                       (item.modifyStatus===0||item.modifyStatus==2||item.modifyStatus==3)"
                                       size="small" class="btn-border-e6a23c" type="text"
                                       @click="showChange(index, 2)"
                                       :disabled="(item.confirmActualSettle == 1 || item.confirmPayment ==1)&&type==='userCar'">
                                订单变更
                            </el-button>
                            <el-button :class="{'sect_btn_select': item.isOrderChange&&item.showIndex==12}"
                                       v-if="(item.orderStatus==72||item.orderStatus==41||item.orderStatus==51||item.orderStatus==61)&&userInfo.userType==1"
                                       size="small" class="btn-border-e6a23c" type="text"
                                       @click="showChange(index, 12)"
                                       :disabled="item.confirmActualSettle == 1 || item.confirmPayment ==1">订单变更
                            </el-button>
                            <el-button class="btn-border-e6a23c"
                                       v-if="item.orderStatus!=22 &&item.orderStatus<32&&item.resourceUseCar==1"
                                       size="small"
                                       :class="{'sect_btn_select': item.isOrderChange&&item.showIndex==9}"
                                       type="text"
                                       @click="showChange(index, 9)">分派抢单
                            </el-button>
                            <el-button class="btn-border-e6a23c" v-if="item.orderStatus==32&&item.resourceUseCar==1"
                                       size="small" :class="{'sect_btn_select': item.isOrderChange&&item.showIndex==11}"
                                       type="text"
                                       @click="showChange(index, 11)">取消抢单
                            </el-button>
                            <el-button v-if="item.orderStatus==23&&userInfo.userType!=1" class="btn-border-e6a23c"
                                       size="small" :class="{'sect_btn_select': item.isOrderChange&&item.showIndex==10}"
                                       @click="showChange(index, 10)" type="text">重新分配供应商
                            </el-button>
                            <el-button class="btn-border-e6a23c" v-if="item.orderStatus==21&&userInfo.userType!=1"
                                       size="small" :class="{'sect_btn_select': item.isOrderChange&&item.showIndex==10}"
                                       type="text"
                                       @click="showChange(index, 10)">分配供应商
                            </el-button>
                            <!--                            <el-button-->
                            <!--                                v-if="item.resourceUseCar==1&&((item.orderStatus==21&&userInfo.userType==0)||-->
                            <!--                                    item.orderStatus==31||item.orderStatus==23)"-->
                            <!--                                size="small"-->
                            <!--                                @click="showChange(index, 8)"-->
                            <!--                                :class="{'sect_btn_select': item.isOrderChange&&item.showIndex==8}"-->
                            <!--                                class="btn-border-e6a23c" type="text">分配司导-->
                            <!--                            </el-button>-->
                            <el-button
                                v-if="(item.orderStatus==21&&userInfo.userType==0)|| item.orderStatus==31||item.orderStatus==23"
                                size="small"
                                @click="showChange(index, 8)"
                                :class="{'sect_btn_select': item.isOrderChange&&item.showIndex==8}"
                                class="btn-border-e6a23c" type="text">分配司导
                            </el-button>
                            <el-popover v-if="item.orderStatus==41&&userInfo.userType==1&&item.resourceUseCar==1"
                                        v-model="item.showPop"
                                        placement="top-end"
                                        width="400"
                                        trigger="click"
                                        @show="popoverShow('showPop', index)">
                                <div style="padding: 10px;font-size: 12px;color: #000000;">
                                    <p style="font-size: 24px;font-weight:700;margin-bottom: 10px">取消司导分配</p>
                                    <p>是否确认取消当前司导分配？取消后，订单将回到“待分配司导”状态</p>
                                    <div style="text-align: right; margin-top: 20px">
                                        <el-button class="el-button_pub" @click="cancelClose(index)">取消</el-button>
                                        <el-button class="el-button_pub" type="primary"
                                                   @click="cancelDistribution(item)" :loading="isBtn">
                                            确定
                                        </el-button>
                                    </div>
                                </div>
                                <el-button slot="reference" size="small" type="text" style="color: #004789">
                                    取消司导分配
                                </el-button>
                            </el-popover>
                            <el-button
                                v-if="item.orderStatus==41||item.orderStatus==42||item.orderStatus==43||
                                    item.orderStatus==51||(item.orderStatus==61&&item.resourceUseCar===0)"
                                size="small"
                                @click="showChange(index, 8)" class="btn-border-e6a23c"
                                :class="{'sect_btn_select': item.isOrderChange&&item.showIndex==8}" type="text">
                                重新分配司导
                            </el-button>
                            <BtnFinishTripOrder v-if="type==='trip'" :info="item"
                                                @success="handleChangeOrderStatus"/>
                            <BtnSetVip :info="item" @success="handleChangeOrderStatus"/>
                            <el-popover
                                v-if="userInfo.userType!=1&&item.orderStatus==71&&(item.cancelStatus==1||item.cancelStatus==3)"
                                placement="top-end" :value="item.showPop"
                                width="490"
                                trigger="manual"
                                :tabindex="index"
                                @show="popoverShow('showPop', index)">
                                <div class="pop-content">
                                    <p style="font-size: 24px;font-weight:700">撤销取消申请</p>
                                    <p style="padding: 10px 0px">将撤销以下取消订单申请，确认继续?</p>
                                    <el-form label-position="right" label-width="60px" class="order-list-table"
                                             v-if="item.cancelStatus==3&&item.orderCancellationDto">
                                        <el-form-item label="用户：">
                                            <table style="width: 340px">
                                                <tr class="font-title-w6">
                                                    <td>原实收金额</td>
                                                    <td>用户退款</td>
                                                    <td>平台赔款</td>
                                                    <td>调整后实收金额</td>
                                                </tr>
                                                <tr>
                                                    <td>{{ item.orderCancellationDto.currentReceiveAmount | currency }}
                                                    </td>
                                                    <td>{{ item.orderCancellationDto.refundAmount | currency }}</td>
                                                    <td>{{ item.orderCancellationDto.indemnityAmount | currency }}</td>
                                                    <td style="color: #ff3b30">
                                                        {{
                                                            (item.orderCancellationDto.currentReceiveAmount -
                                                                item.orderCancellationDto.refundAmount) | currency
                                                        }}
                                                    </td>
                                                </tr>
                                            </table>
                                        </el-form-item>
                                        <el-form-item label="供应商：">
                                            <table style="width: 185px" v-if="item.orderCancellationDto">
                                                <tr>
                                                    <td>原实付金额</td>
                                                    <td>调整后实付金额</td>
                                                </tr>
                                                <tr>
                                                    <td>{{ item.orderCancellationDto.currentSettleAmount | currency }}
                                                    </td>
                                                    <td style="color: #ff3b30">
                                                        {{ item.orderCancellationDto.settleAmount | currency }}
                                                    </td>
                                                </tr>
                                            </table>
                                        </el-form-item>
                                        <el-form-item label="退款原因：">
                                            <p style="width: 100%;line-height: 20px;margin-top: 9px"
                                               v-if="item.orderCancellationDto">
                                                {{ item.orderCancellationDto.refundRemark }}</p>
                                        </el-form-item>
                                    </el-form>
                                    <div style="text-align: right; margin: 0">
                                        <el-button class="btn-border-319" @click="cancelClose(index)"
                                                   style="padding: 8px 20px">取消
                                        </el-button>
                                        <el-button v-if="item.cancelStatus==3" type="primary" :loading="isBtn"
                                                   @click="withdrawOrderCancellation(item)" style="padding: 8px 20px">确认
                                        </el-button>
                                        <el-button v-if="item.cancelStatus==1" type="primary"
                                                   @click="rejectCancelApplication(item)" style="padding: 8px 20px"
                                                   :loading="isBtn">确认
                                        </el-button>
                                    </div>
                                </div>
                                <el-button slot="reference" size="small" @click="handleCancellation(item,index)"
                                           style="padding: 8px 20px;color: #004789" type="text">撤销取消申请
                                </el-button>
                            </el-popover>
                            <el-button size="small" :class="{'sect_btn_select': item.isOrderChange&&item.showIndex==3}"
                                       class="btn-border-e6a23c" type="text"
                                       v-if="userInfo.userType!=1&&item.orderStatus==71&&item.cancelStatus==1"
                                       @click="showChange(index, 3)">处理取消申请
                            </el-button>
                            <el-popover v-if="item.orderStatus==71&&userInfo.userType==1&&item.cancelStatus==3"
                                        placement="top-start" v-model="item.showPop"
                                        width="400"
                                        trigger="click"
                                        @show="popoverShow('showPop', index)">
                                <div style="padding: 10px;font-size: 12px;color: #000000;"
                                     v-if="item.orderCancellationDto">
                                    <p style="font-size: 24px;font-weight:700">处理取消申请</p>
                                    <p style="padding: 10px 0px">请确认结算金额。若有疑问，请联系运营商</p>
                                    <p>原结算金额:¥{{ item.orderCancellationDto.currentSettleAmount | currency }} </p>
                                    <p>现结算金额: <span
                                        style="color: #ff3b30">¥{{
                                            item.orderCancellationDto.settleAmount | currency
                                        }}</span>
                                    </p>
                                    <p>取消理由: <span
                                        style="color: #319BF7">{{ item.orderCancellationDto.refundRemark }}</span></p>
                                    <div style="text-align: right; margin: 0">
                                        <el-button size="mini" type="text" @click="cancelClose(index)">取消</el-button>
                                        <el-button type="primary" size="mini" @click="acceptOrderCancellation(item)"
                                                   :loading="isBtn">
                                            确认并取消订单
                                        </el-button>
                                    </div>
                                </div>
                                <el-button slot="reference" size="small" type="text" style="color: #004789">处理取消申请
                                </el-button>
                            </el-popover>
                            <el-popover v-if="item.modifyStatus==1&&userInfo.userType==1&&item.orderStatus!=71"
                                        placement="top-end" v-model="item.showProcessOrderChangePop"
                                        width="400"
                                        trigger="click"
                                        @show="popoverShow('showProcessOrderChangePop', index)">
                                <div style="padding: 10px;font-size: 12px;color: #000000;"
                                     v-if="item.orderModificationDto">
                                    <p style="font-size: 24px;font-weight:700">处理订单变更</p>
                                    <p style="padding: 10px 0px">请确认结算金额。若有疑问，请联系运营商</p>
                                    <p>原结算金额:¥{{ item.orderModificationDto.currentSettleAmount | currency }} </p>
                                    <p>现结算金额: <span
                                        style="color: #ff3b30">¥{{
                                            item.orderModificationDto.settleAmount | currency
                                        }}</span>
                                    </p>
                                    <p>变更理由: <span
                                        style="color: #319BF7">{{ item.orderModificationDto.refundRemark }}</span></p>
                                    <div style="text-align: right; margin: 0">
                                        <el-button class="btn-border-319" @click="cancelClose(index)">取消</el-button>
                                        <el-button type="primary" @click="acceptOrderModification(item)"
                                                   :loading="isBtn">确认订单变更
                                        </el-button>
                                    </div>
                                </div>
                                <el-button slot="reference" size="small" type="text" style="color: #004789">
                                    处理订单变更
                                </el-button>
                            </el-popover>
                            <el-popover v-if="item.modifyStatus==1&&userInfo.userType!=1&&item.orderStatus!=71"
                                        placement="top-end" :value="item.showPop"
                                        width="490"
                                        trigger="click"
                                        @show="popoverShow('showPop', index)">
                                <div class="pop-content">
                                    <p style="font-size: 24px;font-weight:700">撤销订单变更</p>
                                    <p style="padding: 10px 0px">将撤销以下取消订单申请，确认继续?</p>
                                    <el-form label-position="right" label-width="60px" class="order-list-table">
                                        <el-form-item label="用户：">
                                            <table style="width: 340px">
                                                <tr class="font-title-w6">
                                                    <td>原实收金额</td>
                                                    <td>用户退款</td>
                                                    <td>平台赔款</td>
                                                    <td>调整后实收金额</td>
                                                </tr>
                                                <tr>
                                                    <td>{{ item.orderModificationDto.currentReceiveAmount | currency }}
                                                    </td>
                                                    <td>{{ item.orderModificationDto.refundAmount | currency }}</td>
                                                    <td>{{ item.orderModificationDto.indemnityAmount | currency }}</td>
                                                    <td style="color: #ff3b30">
                                                        {{
                                                            (item.orderModificationDto.currentReceiveAmount -
                                                                item.orderModificationDto.refundAmount) | currency
                                                        }}
                                                    </td>
                                                </tr>
                                            </table>
                                        </el-form-item>
                                        <el-form-item label="供应商：">
                                            <table style="width: 185px" v-if="item.orderModificationDto">
                                                <tr>
                                                    <td>原实付金额</td>
                                                    <td>调整后实付金额</td>
                                                </tr>
                                                <tr>
                                                    <td>{{ item.orderModificationDto.currentSettleAmount | currency }}
                                                    </td>
                                                    <td style="color: #ff3b30">
                                                        {{ item.orderModificationDto.settleAmount | currency }}
                                                    </td>
                                                </tr>
                                            </table>
                                        </el-form-item>
                                        <el-form-item label="退款原因：">
                                            <p style="width: 640px;line-height: 20px;margin-top: 10px"
                                               v-if="item.orderModificationDto">
                                                {{ item.orderModificationDto.refundRemark }}</p>
                                        </el-form-item>
                                    </el-form>
                                    <div style="text-align: right; margin: 0">
                                        <el-button class="btn-border-319" @click="cancelClose(index)">取消</el-button>
                                        <el-button type="primary" @click="withdrawOrderModification(item)"
                                                   :loading="isBtn">确认
                                        </el-button>
                                    </div>
                                </div>
                                <el-button slot="reference" size="small" type="text" style="color: #004789">撤销订单变更
                                </el-button>
                            </el-popover>
                            <!--携程包车订单才有的完成定单按钮-->
                            <el-button class="btn-border-e6a23c"
                                       v-if="([18,24].includes(item.sourceType)&&(item.orderStatus===41||item.orderStatus===42||
                                       item.orderStatus===43||item.orderStatus===51||item.orderStatus===61)&&
                                       item.thirdOrderInfoDto.isSendPrice===0)&&nowTime>item.reserveTime"
                                       size="small" :class="{'sect_btn_select': item.isOrderChange&&item.showIndex==11}"
                                       type="text"
                                       @click="showChange(index, 13)">
                                包车完成
                            </el-button>
                            <!--完成外派司机订单-->
                            <btn-finish-backend-driver-order :info="item"
                                                             v-if="type==='userCar'"
                                                             @success="handleChangeOrderStatus">
                            </btn-finish-backend-driver-order>
                            <!--变更订单运营商-->
                            <change-operator v-if="item.orderStatus===21&&userInfo.refType==0" :info="item"
                                             @success="handleChangeOrderStatus"/>
                            <el-button
                                v-if="userInfo.userType==='0'&&item.sourceType===12"
                                size="small"
                                @click="showChange(index, 'sendEvent')" class="btn-border-e6a23c"
                                type="text">
                                发送事件
                            </el-button>
                        </div>
                    </el-row>
                    <!--<el-collapse-transition>-->
                    <div class="order-list-detail" v-if="item.isOrderChange&&item.showIndex==4">
                        <div class="order-detail clear">
                            <div style="width: 280px;float: left">
                                <p>订单来源：{{ item.sourceType | formatDesc(sourceTypeList,'value','name') }}</p>
                                <p v-if="item.promotionMobile">推广人手机：{{ item.promotionMobile }}</p>
                                <p>支付方式：{{ item.payTypeName ? item.payTypeName : '-' }}</p>
                                <p>支付渠道：{{ item.payChannelName ? item.payChannelName : '-' }}</p>
                                <p>支付主体：{{ item.subjectName ? item.subjectName : '-' }}</p>
                            </div>
                            <div style="float: left">
                                <!--<p v-if="userInfo.userType==0 && item.thirdOrderInfoDto">-->
                                <!--第三方订单编号：{{item.thirdOrderInfoDto.thirdOrderId}}</p>-->
                                <p>支付编号：{{ item.payId ? item.payId : '-' }}</p>
                                <p>
                                    支付时间：{{
                                        item.paymentTime | dateCus('yyyy-MM-dd hh:mm:ss')
                                    }}{{ !item.paymentTime ? '-' : '' }}
                                </p>
                                <p v-if="item.ctripMasterOrderId">携程主订单号：{{ item.ctripMasterOrderId }}</p>
                                <!--<p>-->
                                <!--第三方产品：{{item.thirdOrderInfoDto.thirdProductName || '-'}}</p>-->
                                <!--<p>-->
                                <!--第三方资源：{{item.thirdOrderInfoDto.thirdResourceName || '-'}}</p>-->
                                <!--<p>第三方订单金额：{{item.thirdOrderInfoDto.thirdOrderAmount | currency}}</p>-->
                                <!--<p>第三方优惠金额：{{item.thirdOrderInfoDto.thirdDiscountsAmount | currency}}</p>-->
                                <!--<p>第三方实收金额：{{item.thirdOrderInfoDto.thirdOrderPayAmount | currency}}</p>-->
                            </div>
                        </div>
                        <div class="refund-record" v-if="item.orderRefundDtos&&item.orderRefundDtos.length>0">
                            <p class="refund-record-label">退款记录</p>
                            <table class="refund-record-table order-list-table">
                                <tr class="font-title-w6">
                                    <td>类型</td>
                                    <td>原实收金额</td>
                                    <td>用户退款</td>
                                    <td>平台赔款</td>
                                    <td>调整后实收金额</td>
                                    <td>原实付金额</td>
                                    <td>调整后实付金额</td>
                                    <td>退款原因</td>
                                    <td>提交人</td>
                                    <td>提交时间</td>
                                    <td>退款编号</td>
                                </tr>
                                <tr v-for="or in item.orderRefundDtos" :key="or.id">
                                    <td>{{ or.operateType }}</td>
                                    <td>{{ or.currentReceiveAmount | currency }}</td>
                                    <td>{{ or.refundAmount | currency }}</td>
                                    <td>{{ or.indemnityAmount | currency }}</td>
                                    <td>{{ or.receiveAmount | currency }}</td>
                                    <td>{{ or.currentSettleAmount | currency }}</td>
                                    <td>{{ or.settleAmount | currency }}</td>
                                    <td>{{ or.refundRemark }}</td>
                                    <td>{{ or.submitLoginuserName }}</td>
                                    <td>{{ or.submitTime | dateCus('yyyy-MM-dd hh:mm') }}</td>
                                    <td><p>{{ or.refundPayId }}</p></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="serveice-note" v-if="item.isOrderChange&&item.showIndex==5">
                        <div class="serveice-note-input" style="position: relative">
                            <el-form :model="serveiceForm" ref="serveiceForm" size="small">
                                <el-form-item prop="serveiceNote" :rules="[{ required: true, message: '请填写内容'}]"
                                              style="margin-bottom: 0">
                                    <el-input type="textarea" :rows="7" style="width: 700px;"
                                              v-model="serveiceForm.serveiceNote"></el-input>
                                </el-form-item>
                                <div style="position: absolute;bottom: 0;left: 720px">
                                    <el-button class="btn-border-319" style="width: 110px"
                                               @click="showChange(index)">
                                        取消
                                    </el-button>
                                    <el-button type="primary" style="width: 110px"
                                               @click="addServiceInfo(item, index)" :loading="isBtn">保存
                                    </el-button>
                                </div>
                            </el-form>
                        </div>
                        <div class="serveice-note-list" style="margin-top: 10px" v-if="serviceInfoList.length>0">
                            <p style="font-weight: 700;line-height: 40px">客服备注记录：</p>
                            <ul style="max-height: 200px;overflow: scroll;overflow-x:hidden;">
                                <li class="customer-service-record" style="font-size: 12px;"
                                    v-for="service in serviceInfoList"
                                    :key="service.id">
                                    <p style="color: #909399;">
                                        {{ service.loginName }}（{{ service.mobile }}）{{
                                            service.createTime |
                                                dateCus('yyyy-MM-dd hh:mm:ss')
                                        }}</p>
                                    <p>{{ service.orderRemark }}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="serveice-note clear"
                         v-if="item.isOrderChange&&(item.showIndex==1||item.showIndex==3)">
                        <!--style="height: 170px;"-->
                        <div class="clear" v-if="!showNext">
                            <el-form label-position="right" label-width="150px" :model="distForm" ref="distForm"
                                     size="small">
                                <div style="float: left">
                                    <el-form-item label="用户退款金额：" prop="refundAmount"
                                                  :rules="[{ required: true, message: '请填写金额'},
                                                  {validator:(rule, value, callback) => {
                                                      let price = (item.refundAmount?(item.paymentAmount-item.refundAmount):item.paymentAmount) || 0;
                                                   if (price - value<0) {
                                                        callback('退款金额不得大于实收金额');
                                                    } else {
                                                        callback();
                                                    }}, trigger: 'blur'}]">
                                        <el-input placeholder="退款金额不得超过实收金额" type="number" :min="0"
                                                  style="width: 330px"
                                                  v-model="distForm.refundAmount">
                                            <template slot="prefix"><span
                                                style="color: #000B15;padding-left: 10px">¥</span></template>
                                            <span slot="suffix">
                                                    <span style="color: #000B15;padding-right: 10px">当前实收:
                                                        <span style="color: #ff3b30">
                                                            {{
                                                                (item.refundAmount ? (item.paymentAmount - item.refundAmount) : item.paymentAmount) || 0
                                                            }}
                                                        </span>
                                                    </span>
                                            </span>
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item label="供应商结算金额：" prop="actualSettleAmount"
                                                  :rules="[{ required: true, message: '请填写金额'}]">
                                        <el-input placeholder="新实付金额" type="number" :min="0" style="width: 330px"
                                                  v-model="distForm.actualSettleAmount">
                                            <template slot="prefix"><span
                                                style="color: #333;padding-left: 10px">¥</span></template>
                                            <template slot="suffix">
                                                    <span style="color: #000B15;padding-right: 10px">当前实付: <span
                                                        style="color: #ff3b30">{{
                                                            item.actualSettleAmount
                                                        }}</span></span>
                                            </template>
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item label="平台赔付用户金额：" prop="indemnityAmount"
                                                  :rules="[{ required: true, message: '请填写金额'}]">
                                        <el-input placeholder="0" style="width: 330px" :min="0" type="number"
                                                  v-model="distForm.indemnityAmount">
                                            <template slot="prefix"><span
                                                style="color: #333;padding-left: 10px">¥</span></template>
                                        </el-input>
                                    </el-form-item>
                                </div>
                                <div style="float: left">
                                    <el-form-item label="退款原因：" label-width="100px" prop="refundRemark"
                                                  style="margin-bottom: 0;"
                                                  :rules="[{ required: true, message: '请填写退款原因'}]">
                                        <el-input placeholder="请输入退款理由，核损明细等信息" type="textarea"
                                                  class="refund-reason-textarea"
                                                  v-model="distForm.refundRemark"></el-input>
                                    </el-form-item>
                                </div>
                                <div style="float: left;">
                                    <el-button type="primary"
                                               style="width: 110px;margin-top: 87px;margin-left: 15px"
                                               @click="distDierector">
                                        下一步
                                    </el-button>
                                </div>

                            </el-form>
                        </div>
                        <div v-else>
                            <div>
                                <el-button type="text" icon="el-icon-arrow-left" @click="showNext=false">
                                    上一步
                                </el-button>
                            </div>
                            <el-form label-position="right" class="order-list-table">
                                <div style="float: left;width: 400px">
                                    <el-form-item label="用户：" label-width="68px">
                                        <table style="width: 340px">
                                            <tr class="font-title-w6">
                                                <td>原实收金额</td>
                                                <td>用户退款</td>
                                                <td>平台赔款</td>
                                                <td>调整后实收金额</td>
                                            </tr>
                                            <tr>
                                                <td>{{ item.paymentAmount | currency }}</td>
                                                <td>{{ distForm.refundAmount | currency }}</td>
                                                <td>{{ distForm.indemnityAmount | currency }}</td>
                                                <td style="color: #ff3b30">
                                                    {{ (item.paymentAmount - distForm.refundAmount) | currency }}
                                                </td>
                                            </tr>
                                        </table>
                                    </el-form-item>
                                    <el-form-item label="供应商：">
                                        <table style="width: 185px">
                                            <tr>
                                                <td>原实付金额</td>
                                                <td>调整后实付金额</td>
                                            </tr>
                                            <tr>
                                                <td>{{ item.actualSettleAmount | currency }}</td>
                                                <td style="color: #ff3b30">{{ distForm.actualSettleAmount | currency }}
                                                </td>
                                            </tr>
                                        </table>
                                    </el-form-item>
                                </div>
                                <div style="float: left;margin-left: 20px">
                                    <el-form-item label="退款原因：">
                                        <p style="width: 640px;line-height: 20px;margin-top: 10px">
                                            {{ distForm.refundRemark }}</p>
                                    </el-form-item>
                                </div>
                            </el-form>
                            <div style="position: absolute;bottom: 30px;right: 10px">
                                <div style="float: right">
                                    <el-button style="width: 110px" class="btn-border-319"
                                               @click="showChange(index)">
                                        取消
                                    </el-button>
                                    <el-button style="width: 110px" type="primary" :loading="isBtn"
                                               @click="orderChange(item)">
                                        确认
                                    </el-button>
                                </div>
                                <div style="float: right;" class="word-desc">
                                    <span>确认变更信息？</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--重新分配司导-->
                    <div class="serveice-note" v-if="item.isOrderChange&&item.showIndex==8">
                        <div v-if="!showNext">
                            <p style="font-size: 12px;color: #FF3B30;">司导信息将推送给出行人，请填写真实信息！</p>
                            <p v-if="item.distributionGuiderDto&&(item.orderStatus==41||item.orderStatus==42||item.orderStatus==43||item.orderStatus==51)"
                               style="font-size: 12px;padding-top: 6px">
                                将原司导:
                                <span v-if="changeDriverType==0">
                                {{ item.distributionGuiderDto.touristGuideName }}，
                                {{ item.distributionGuiderDto.touristGuideMobile }}
                            </span>
                                <span v-if="changeDriverType==1">
                                    {{ item.distributionGuiderDto.guiderName }}，
                                {{ item.distributionGuiderDto.guiderMobile }}，
                                    {{ item.distributionGuiderDto.carNumber }},
                                    {{ item.distributionGuiderDto.carColor }},
                                    司机到手价￥{{ item.driverPrice | currency }}
                                </span>
                                &nbsp;&nbsp;&nbsp;
                                更换为新司导:
                            </p>
                            <div class="serveice-note-input" style="padding-top: 10px">
                                <el-form :model="distForm" ref="distForm" :rules="distRule" :inline="true" size="small">
                                    <div v-if="changeDriverType==0">
                                        <el-form-item label="司导姓名" prop="touristGuideName" class="public-tip_input"
                                                      style="margin-bottom: 0">
                                            <el-input style="width: 150px"
                                                      v-model="distForm.touristGuideName"
                                                      placeholder="请输入司导姓名">
                                            </el-input>
                                        </el-form-item>
                                        <el-form-item label="司导手机" prop="touristGuideMobile"
                                                      class="public-tip_input"
                                                      style="margin-bottom: 0">
                                            <el-input style="width: 150px"
                                                      maxlength="11"
                                                      v-model="distForm.touristGuideMobile"
                                                      placeholder="请输入司导手机号"></el-input>
                                        </el-form-item>
                                    </div>
                                    <div v-if="changeDriverType==1">
                                        <el-form-item label="司导姓名" prop="guiderName" class="public-tip_input"
                                                      style="margin-bottom: 0">
                                            <el-input style="width: 150px"
                                                      @input="onInputQuery(1)"
                                                      v-model="distForm.guiderName"
                                                      @blur="onInputBlur()"
                                                      placeholder="请输入司导姓名">
                                            </el-input>
                                            <div v-if="driverListShow == 1"
                                                 class="driver-info-res"
                                                 :class="{'info-res_z':driverList && driverListShow == 1}">
                                                <ul>
                                                    <li v-for="(item, index) in driverList" :key="index"
                                                        style="cursor: pointer"
                                                        :style="{color: item.cancelAccountStatus === 2?'#999':'',pointerEvents:item.cancelAccountStatus === 2?'none':'auto'}"
                                                        @click="handName(item)">
                                                        {{ item.driverName }}&nbsp;&nbsp;&nbsp;{{ item.driverMobile }}&nbsp;&nbsp;&nbsp;<span
                                                        class="color-danger">{{
                                                            item.cancelAccountStatus === 2 ? '已申请注销' : ''
                                                        }}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </el-form-item>
                                        <el-form-item label="司导手机" prop="guiderMobile" class="public-tip_input"
                                                      style="margin-bottom: 0">
                                            <el-input style="width: 150px"
                                                      maxlength="11"
                                                      @input="onInputQuery(2)"
                                                      v-model="distForm.guiderMobile"
                                                      @blur="onInputBlur"
                                                      placeholder="请输入司导手机号"></el-input>
                                            <div v-if="driverListShow == 2"
                                                 class="driver-info-res"
                                                 :class="{'info-res_z':driverList && driverListShow == 2}">
                                                <ul>
                                                    <li v-for="(item, index) in driverList" :key="index"
                                                        style="cursor: pointer;"
                                                        :style="{color: item.cancelAccountStatus === 2?'#999':'',pointerEvents:item.cancelAccountStatus === 2?'none':'auto'}"
                                                        @click="handName(item)">
                                                        {{ item.driverName }}&nbsp;&nbsp;&nbsp;{{ item.driverMobile }}&nbsp;&nbsp;&nbsp;<span
                                                        class="color-danger">{{
                                                            item.cancelAccountStatus === 2 ? '已申请注销' : ''
                                                        }}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </el-form-item>
                                        <!--飞猪司机必须要身份证-->
                                        <el-form-item label="身份证"
                                                      prop="idCardNumber"
                                                      v-if="item.sourceType===15"
                                                      :rules="[{validator:validatorIdCardNumber,trigger: 'blur'}]">
                                            <el-input v-model="distForm.idCardNumber"
                                                      placeholder="请输入身份证"></el-input>
                                        </el-form-item>
                                        <div style="display: inline-block">
                                            <div>
                                                <el-form-item label="车牌号" style="margin-bottom: 0"
                                                              prop="selectShortCity">
                                                    <el-select v-model="distForm.selectShortCity" clearable
                                                               :disabled="!freeDistributionOfDrivers"
                                                               style="width: 65px">
                                                        <el-option v-for="pc in plateCity"
                                                                   :key="pc.value"
                                                                   :label="pc.value"
                                                                   :value="pc.value">
                                                        </el-option>
                                                    </el-select>
                                                </el-form-item>
                                                <el-form-item prop="carNum" style="margin-bottom: 0">
                                                    <el-input style="width: 180px" v-model="distForm.carNum"
                                                              maxlength="7"
                                                              :disabled="!freeDistributionOfDrivers"
                                                              @change="carNumChange()"
                                                              minlength="5" placeholder="5-7位数字、字母组成"></el-input>
                                                </el-form-item>
                                            </div>
                                            <p v-if="distForm.validityEndTime&&distForm.carNum&&`${distForm.selectShortCity}${distForm.carNum}`==selectedDriverInfo.carNumberPlate"
                                               style="font-size: 12px;padding-left: 60px;"
                                               class="color-danger">
                                                车辆技术服务有效期至{{
                                                    distForm.validityEndTime | dateCus('yyyy年MM月dd日')
                                                }}</p>
                                        </div>
                                        <el-form-item label="车辆品牌："
                                                      v-if="freeDistributionOfDrivers"
                                                      prop="carBrand">
                                            <el-input v-model="distForm.carBrand"
                                                      placeholder="品牌"
                                                      @blur="handleChangeCarBrandModel"
                                                      style="width: 100%"></el-input>
                                        </el-form-item>
                                        <el-form-item label="车辆型号："
                                                      v-if="freeDistributionOfDrivers"
                                                      prop="carModel">
                                            <el-input v-model="distForm.carModel"
                                                      placeholder="型号"
                                                      @blur="handleChangeCarBrandModel"
                                                      style="width: 100%"></el-input>
                                        </el-form-item>
                                        <el-form-item label="品牌型号：" v-if="!freeDistributionOfDrivers">
                                            <el-cascader placeholder="品牌"
                                                         filterable
                                                         :disabled="true"
                                                         v-model="distForm.carBrandModel"
                                                         :options="brandModelLsit"
                                                         style="width: 100%"
                                                         @change="selectCarBrandModel"></el-cascader>
                                        </el-form-item>

                                        <el-form-item label="车型：" v-if="freeDistributionOfDrivers">
                                            <seat-model :isAll="true" v-model="distForm.carType"></seat-model>
                                        </el-form-item>
                                        <el-form-item label="车辆颜色：" prop="carColor">
                                            <el-input style="width: 180px" v-model="distForm.carColor" maxlength="5"
                                                      :disabled="!freeDistributionOfDrivers"
                                                      placeholder="颜色"></el-input>
                                        </el-form-item>
                                        <el-form-item label="司机到手价" prop="driverPrice" class="their-price"
                                                      style="margin-bottom: 0">
                                            <el-input prefix-icon="icon icon-daifukuan" style="width: 180px"
                                                      @change="clearAmountNum(distForm.driverPrice, 'driverPrice', 2)"
                                                      v-model="distForm.driverPrice"
                                                      placeholder="请输入司机到手价"></el-input>
                                        </el-form-item>
                                        <el-form-item label="所属供应商" prop="supplierId" class="their-price"
                                                      v-if="userInfo.userType==0">
                                            <el-select v-model="distForm.supplierId"
                                                       clearable
                                                       filterable
                                                       @change="selectSupplier1"
                                                       no-match-text="该供应商不存在"
                                                       placeholder="供应商"
                                                       size="small">
                                                <el-option
                                                    v-for="item in supplierList"
                                                    :key="item.id"
                                                    :label="item.supplierName"
                                                    :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                    <el-form-item class="mt10">
                                        <!--新增向导服务 既有司机又有导游-->
                                        <el-switch v-if="['18','21'].includes(item.productForm)" class="mr10"
                                                   v-model="changeDriverType" :inactive-value="0"
                                                   :active-value="1"
                                                   active-text="司机" inactive-text="导游">
                                        </el-switch>
                                        <el-button type="danger" @click="handName()">清空</el-button>
                                        <el-button type="primary" style="width: 110px" @click="distDierector(1,item)">
                                            下一步
                                        </el-button>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </div>
                        <div v-else>
                            <div>
                                <el-button type="text" icon="el-icon-arrow-left" @click="showNext=false">
                                    上一步
                                </el-button>
                            </div>
                            <div>
                                <p v-if="item.distributionGuiderDto&&(item.orderStatus==41||item.orderStatus==42||item.orderStatus==43||item.orderStatus==51)"
                                   style="font-size: 12px;">
                                    将原司导:
                                    <span v-if="changeDriverType==0">
                                {{ item.distributionGuiderDto.touristGuideName }}，
                                {{ item.distributionGuiderDto.touristGuideMobile }}
                            </span>
                                    <span v-if="changeDriverType==1">
                                        {{ item.distributionGuiderDto.guiderName }}，
                                    {{ item.distributionGuiderDto.guiderMobile }}，
                                    {{ item.distributionGuiderDto.carNumber }},
                                    司机到手价￥{{ item.driverPrice | currency }}
                                    </span>
                                    &nbsp;&nbsp;&nbsp;
                                    更换为新司导:
                                </p>
                                <div style="line-height: 40px">
                                    <p v-if="changeDriverType==0">
                                        <span style="font-weight: 600;">司导姓名：</span>
                                        <span style="color: #319BF7">{{ distForm.touristGuideName }}</span>
                                        <span style="font-weight: 600;padding-left: 20px">司导手机号：</span>
                                        <span style="color: #319BF7">{{ distForm.touristGuideMobile }}</span>
                                    </p>
                                    <p v-if="changeDriverType==1">
                                        <span style="font-weight: 600;">司导姓名：</span>
                                        <span style="color: #319BF7">{{ distForm.guiderName }}</span>
                                        <span style="font-weight: 600;padding-left: 20px">司导手机号：</span>
                                        <span style="color: #319BF7">{{ distForm.guiderMobile }}</span>
                                        <span style="font-weight: 600;padding-left: 20px">身份证：</span>
                                        <span style="color: #319BF7">{{ distForm.idCardNumber }}</span>
                                        <span style="font-weight: 600;padding-left: 20px">车牌号：</span>
                                        <span style="color: #319BF7">{{
                                                distForm.selectShortCity + distForm.carNum
                                            }}</span>
                                        <span style="font-weight: 600;padding-left: 20px">品牌型号：</span>
                                        <span style="color: #319BF7">{{ distForm.carBrandModel.join('/') }}</span>
                                        <span style="font-weight: 600;padding-left: 20px">车型：</span>
                                        <span style="color: #319BF7">{{
                                                distForm.carType ? formatCarModel(distForm.carType).name : ''
                                            }}</span>
                                        <span style="font-weight: 600;padding-left: 20px">颜色：</span>
                                        <span style="color: #319BF7">{{ distForm.carColor }}</span>
                                        <span style="font-weight: 600;padding-left: 20px">司机到手价</span>
                                        <span style="color: #319BF7">￥{{ distForm.driverPrice | currency }}</span>
                                        <span style="font-weight: 600;padding-left: 20px" v-if="distForm.supplierName">所属供应商</span>
                                        <span style="color: #319BF7">{{ distForm.supplierName }}</span>
                                    </p>
                                    <!--<p class="driver-confirm_prompt" v-if="driverLibShow">-->
                                    <!--平台将把订单推送至900游司导端APP，请提醒司机登录查看！-->
                                    <!--</p>-->
                                    <!--<p class="driver-confirm_prompt" v-else>-->
                                    <!--该司机未注册900游司导端APP，将无法接收订单信息。请提醒司机立即注册！-->
                                    <!--</p>-->
                                </div>

                                <div style="text-align: right">
                                    <div class="word-desc" style="display: inline-block">
                                        <span>确认{{
                                                (item.orderStatus == 41 || item.orderStatus == 42 || item.orderStatus == 43 || item.orderStatus == 51) ? '重新' : ''
                                            }}分配司导信息？</span>
                                    </div>
                                    <el-button style="width: 110px" class="btn-border-319"
                                               @click="showChange(index)">
                                        取消
                                    </el-button>
                                    <el-button style="width: 110px" type="primary" :loading="isBtn"
                                               @click="addDierector(item)">
                                        确认
                                    </el-button>
                                </div>

                            </div>
                            <!--<p></p>-->


                        </div>
                    </div>
                    <div class="serveice-note" v-if="item.isOrderChange&&item.showIndex==10">
                        <p v-if="item.orderStatus==23" style="font-size: 12px;">将原供应商: {{ item.supplierName }}</p>
                        <div v-if="!showNext">
                            <div class="serveice-note-input" style="padding-top: 10px">
                                <el-form :model="distForm" ref="distForm" :rules="distRule" :inline="true" size="small">
                                    <el-form-item label="将订单分配给：" prop="supplierId" style="margin-bottom: 10px;">
                                        <!--<el-input style="width: 150px" v-model="distForm.guiderName"-->
                                        <!--placeholder="请输入司导姓名"></el-input>-->
                                        <el-select v-model="distForm.supplierIndex" clearable
                                                   filterable
                                                   @change="selectSupplier"
                                                   no-match-text="该供应商不存在"
                                                   placeholder="供应商"
                                                   size="small">
                                            <el-option
                                                v-for="(item,index) in supplierList"
                                                :key="item.id"
                                                :label="item.supplierName"
                                                :value="index">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="结算金额：" prop="settleAmount" style="margin-bottom: 10px;">
                                        <el-input style="width: 150px" v-model="distForm.settleAmount" type="number"
                                                  @change="clearAmountNum(distForm.settleAmount,'settleAmount', 2)">
                                            <span slot="suffix">元</span>
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item style="margin-bottom: 0">
                                        <el-button type="primary" style="width: 110px" @click="distDierector">
                                            下一步
                                        </el-button>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </div>
                        <div v-else>
                            <div>
                                <el-button type="text" icon="el-icon-arrow-left" @click="showNext=false">
                                    上一步
                                </el-button>
                            </div>
                            <!--<p></p>-->
                            <div style="float: left;line-height: 40px">
                                <span style="font-weight: 600;">将订单分配给：</span><span
                                style="color: #319BF7">{{ distForm.supplierName }}</span>
                                <span style="font-weight: 600;">结算金额：</span><span
                                style="color: #319BF7">{{ distForm.settleAmount | currency }}</span>
                            </div>
                            <div style="float: right">
                                <el-button style="width: 110px" class="btn-border-319"
                                           @click="showChange(index)">
                                    取消
                                </el-button>
                                <el-button style="width: 110px" type="primary" :loading="isBtn"
                                           @click="addSupplier(item)">
                                    确认
                                </el-button>
                            </div>
                            <div style="float: right;" class="word-desc">
                                <span> 确认{{ item.orderStatus == 23 ? '重新' : '' }}分配的供应商？</span>
                            </div>
                        </div>
                    </div>
                    <!--</el-collapse-transition>-->
                    <!--分派抢单 begin-->
                    <div class="serveice-note" style="display: flex" v-if="item.isOrderChange&&item.showIndex==9">
                        <div style="width: calc(100% - 250px)">
                            <div class="serveice-note-input" style="padding-top: 10px">
                                <el-form size="small" :model="distForm" ref="distForm" :rules="distRule">
                                    <el-form-item label="分派类型：" required
                                                  v-if="item.sourceType==26&&item.orderStatus==21&&['0','2'].includes(userInfo.userType)">
                                        <el-switch v-model="distForm.type" active-text="供应商抢单"
                                                   inactive-text="司机抢单"></el-switch>
                                    </el-form-item>
                                    <el-form-item :label="distForm.type?'供应商结算价：':'司机到手价：'"
                                                  prop="driverHandPrice"
                                                  style="margin-bottom: 10px;">
                                        <el-input prefix-icon="icon icon-daifukuan" style="width: 180px"
                                                  @change="clearAmountNum(distForm.driverHandPrice, 'driverHandPrice', 2)"
                                                  v-model="distForm.driverHandPrice"
                                                  :placeholder="distForm.type?'供应商结算价':'司机到手价'">
                                            <span slot="suffix">元</span>
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <div v-if="distForm.type">
                                            订单将分配至抢单列表，运营商<span
                                            style="color: #409EFF">{{ item.operatorName }}</span>下的所有供应商均可抢单，是否确认分派？
                                        </div>
                                        <div v-else>
                                            订单将分派至抢单列表，<span v-if="item.orderStatus==31">供应商</span>
                                            <span style="color: #409EFF"
                                                  v-if="item.orderStatus!=31">{{ item.operatorName }}</span>
                                            <span style="color: #409EFF"
                                                  v-if="item.orderStatus==31">{{ item.supplierName }}</span>
                                            下的所有司导均可抢单，是否确认分派？
                                        </div>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </div>
                        <div style="padding-top: 52px">
                            <el-button style="width: 110px" class="btn-border-319"
                                       @click="showChange(index)">
                                取消
                            </el-button>
                            <el-button style="width: 110px" type="primary" :loading="isBtn" @click="dispatchGrab(item)">
                                确认
                            </el-button>
                        </div>
                    </div>
                    <!--分派抢单 end-->
                    <div class="serveice-note" style="display: flex" v-if="item.isOrderChange&&item.showIndex==11">
                        <div>
                            <div class="serveice-note-input" style="padding-top: 10px">
                                <el-form size="small">
                                    <el-form-item>
                                        是否确认取消抢单，改为人工派单？
                                    </el-form-item>
                                </el-form>
                            </div>
                        </div>
                        <div style="padding-top: 10px;margin-left: 25px">
                            <el-button style="width: 110px" class="btn-border-319"
                                       @click="showChange(index)">
                                取消
                            </el-button>
                            <el-button style="width: 110px" type="primary" :loading="isBtn"
                                       @click="dispatchCancelGrab(item)">
                                确认
                            </el-button>
                        </div>
                    </div>
                    <!--完成携程订单 begin-->
                    <div class="serveice-note" style="display: flex" v-if="item.isOrderChange&&item.showIndex==13">
                        <el-form ref="completeForm" :inline="true"
                                 size="small"
                                 :model="completeForm"
                                 label-width="120px">
                            <!--                            <el-form-item label="用车公里数" required prop="distance"-->
                            <!--                                          :rules="[{ required: true, message: '用车公里数不能为空'}]">-->
                            <!--                                <el-input type="number" v-model="completeForm.distance"></el-input>-->
                            <!--                            </el-form-item>-->
                            <!--                            <el-form-item label="用车时长（分）" required prop="useTime"-->
                            <!--                                          :rules="[{ required: true, message: '用车时长不能为空'}]">-->
                            <!--                                <el-input placeholder="用车时长" v-model="completeForm.useTime">-->
                            <!--                                    <span slot="suffix">分钟</span>-->
                            <!--                                </el-input>-->
                            <!--                            </el-form-item>-->
                            <!--                            <el-form-item label="用车完成时间" required prop="finishTime"-->
                            <!--                                          :rules="[{ required: true, message: '用车完成时间不能为空'}]">-->
                            <!--                                <el-date-picker-->
                            <!--                                    v-model="completeForm.finishTime"-->
                            <!--                                    type="datetime"-->
                            <!--                                    placeholder="用车完成时间"-->
                            <!--                                    align="right">-->
                            <!--                                </el-date-picker>-->
                            <!--                            </el-form-item>-->
                            <!--                            <el-form-item label="超公里费用">-->
                            <!--                                <el-input type="number" v-model="completeForm.exceedDistanceFee"></el-input>-->
                            <!--                            </el-form-item>-->
                            <!--                            <el-form-item label="超时长费用">-->
                            <!--                                <el-input type="number" v-model="completeForm.exceedTimeFee"></el-input>-->
                            <!--                            </el-form-item>-->
                            <!--                            <el-form-item label="其他金额">-->
                            <!--                                <el-input type="number" v-model="completeForm.otherFee"></el-input>-->
                            <!--                            </el-form-item>-->
                            <!--                            <el-form-item label="备注">-->
                            <!--                                <el-input type="textarea"-->
                            <!--                                          v-model="completeForm.remark"-->
                            <!--                                          placeholder="备注"-->
                            <!--                                ></el-input>-->
                            <!--                            </el-form-item>-->
                            <el-form-item>
                                是否确认包车完成
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="onCompleteSubmit(item)">确定</el-button>
                                <el-button @click="showChange(index)">取消</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                    <!--完成携程订单 end-->
                    <el-dialog
                        title="行程信息"
                        custom-class="trip-info-dialog"
                        :visible="item.isOrderChange&&item.showIndex==6"
                        width="1000px"
                        :show-close="false">
                        <header class="trip-info-dialog-header">
                            <el-radio-group v-model="item.tripInfoDialog" @change="handleTripInfo(item,index)">
                                <el-radio-button label="1">行程信息</el-radio-button>
                                <el-radio-button label="2" v-if="item.isOrderChange&&item.showIndex==6">预计行驶轨迹
                                </el-radio-button>
                                <el-radio-button label="3" v-if="item.orderStatus==61">司机实际行驶轨迹
                                </el-radio-button>
                            </el-radio-group>
                            <el-button @click="showChange(index)">关闭</el-button>
                        </header>
                        <div style="height: 500px;padding-bottom: 20px;overflow: hidden;overflow-y: scroll">
                            <trip-info v-if="item.tripInfoDialog==1" :info="item"></trip-info>
                            <gaode-map v-if="item.tripInfoDialog==2" :id="item.orderId" :info="item"
                                       @tripInfo="getTripInfo"></gaode-map>
                            <track-map v-if="item.tripInfoDialog==3" :id="item.orderId"
                                       :track-info="trackList"></track-map>
                        </div>
                    </el-dialog>
                    <div class="order-list-detail" v-if="item.isOrderChange&&item.showIndex==7"
                         style="height: 200px;background: #F0F8FF;overflow: scroll;">
                        <div class="refund-record" v-if="orderLogList&&orderLogList.length>0">
                            <table class="refund-record-table order-list-table">
                                <tr v-for="or in orderLogList" :key="or.id">
                                    <td style="width: 200px">{{ or.operateTime | dateCus('yyyy-MM-dd hh:mm:ss') }}</td>
                                    <td style="width: 200px">{{ or.operateName }}</td>
                                    <td v-html="or.operateContent"></td>
                                </tr>
                            </table>
                        </div>
                        <p v-else style="padding: 12px">暂无操作记录</p>
                    </div>
                    <div class="order-list-detail" v-if="item.isOrderChange&&item.showIndex==60"
                         style="height: 200px;background: #F0F8FF;overflow: scroll;">
                        <div class="refund-record"
                             v-if="item.orderEvaluationRecordDtoList && item.orderEvaluationRecordDtoList.length > 0">
                            <div v-for="(evaluateItem, evaluateIndex) in item.orderEvaluationRecordDtoList"
                                 :key="evaluateIndex" style="font-size: 14px;color: #3C3C3C">
                                <span v-if="evaluateItem.evaluationType == 0">乘客对司机的评价：</span>
                                <span v-if="evaluateItem.evaluationType == 1">司机对乘客的评价：</span>
                                <span>{{ evaluateItem.createTime | dateCus('yyyy.MM.dd hh:mm') }}</span>
                                <span>{{ evaluateItem.evaluationLevel }}星</span>
                                <span>{{ evaluateItem.evaluation }}</span>
                            </div>
                        </div>
                        <p v-else style="padding: 12px">暂无评价记录</p>
                    </div>
                    <div class="serveice-note clear"
                         v-if="item.isOrderChange&&item.showIndex==2">
                        <!--style="height: 170px;"-->
                        <div class="clear order-change" v-if="!showNext">
                            <el-form label-position="left" label-width="100px" :model="distForm" ref="distForm">
                                <!--<el-form-item label-width="0px">-->
                                <div style="margin-bottom: 10px">
                                    <el-checkbox v-model="isRecevable" label="用户应收金额变更"></el-checkbox>
                                    <el-checkbox v-model="isBill" label="供应商结算金额变更"></el-checkbox>
                                    <el-checkbox v-model="isGuider" label="司导结算金额变更"
                                                 v-if="userInfo.refType==0"></el-checkbox>
                                </div>
                                <!--</el-form-item>-->
                                <div style="display: flex;align-items: center">
                                    <div style="display: inline-block;">
                                        <div class="clear" style="margin-bottom: 15px" v-if="isRecevable">
                                            <div>
                                                <p v-if="item.paymentAmount>0"
                                                   style="font-size: 12px;color:#DB9117;padding-left: 100px">
                                                    当前订单存在实际支付，退款操作将原路退款至用户账户</p>
                                                <p v-else style="font-size: 12px;color:#DB9117;padding-left: 100px">
                                                    当前订单不存在实际支付，退款操作仅修改应收金额</p>
                                            </div>
                                            <div style="float: left">
                                                <el-form-item label="应收变更：" prop="refundAmount"
                                                              :rules="[{ required: true, message: '请填写金额'},
                                                  {validator:(rule, value, callback) => {
                                                      let price = item.paymentAmount>0?item.paymentAmount:item.receivableAmount;
                                                   if ((price - value<0)&&receivableChange==='1') {
                                                        callback('退款金额不得大于实收金额');
                                                    } else {
                                                        callback();
                                                    }}, trigger: 'blur'}]">
                                                    <el-input
                                                        :placeholder="`请输入${receivableChange==='0'?'补款金额':'退款金额（不超过当前'+(item.paymentAmount>0?'实收）':'应收）')}`"
                                                        type="number" :min="0"
                                                        style="width: 470px;line-height:38px"
                                                        v-model="distForm.refundAmount">
                                                        <el-select v-model="receivableChange" slot="prepend"
                                                                   placeholder="请选择" style="width: 74px"
                                                                   class="select-change">
                                                            <el-option label="补款" value="0"></el-option>
                                                            <el-option label="退款" value="1"></el-option>
                                                        </el-select>
                                                        <template slot="prefix"><span
                                                            style="color: #000B15;">¥</span></template>
                                                        <span slot="suffix">
                                                    <span
                                                        style="color: #000B15;padding-right: 10px;">当前{{
                                                            item.paymentAmount > 0 ? '实收' : '应收'
                                                        }}:
                                                        <span style="color: #ff3b30">
                                                            {{
                                                                item.paymentAmount > 0 ? item.paymentAmount : item.receivableAmount
                                                            }}
                                                        </span>
                                                    </span>
                                            </span>
                                                    </el-input>
                                                </el-form-item>
                                            </div>
                                            <div style="float: left;padding-left: 10px">
                                                <el-form-item label="变更理由：" label-width="100px" prop="refundRemark"
                                                              :rules="[{ required: true, message: '请填写变更理由'}]">
                                                    <el-input placeholder="请输入变更理由，核损明细等信息" type="text"
                                                              v-model="distForm.refundRemark"></el-input>
                                                </el-form-item>
                                            </div>
                                        </div>
                                        <div class="clear" style="margin-bottom: 15px" v-if="isBill">
                                            <div style="float: left">
                                                <el-form-item label="结算变更：" prop="modifySettleAmount"
                                                              :rules="[{ required: true, message: '请填写金额'},
                                                  {validator:(rule, value, callback) => {
                                                      let price = item.settleAmount || 0;
                                                   if ((price - value<0)&&billChange==='1') {
                                                        callback('减少金额不得大于结算金额');
                                                    } else {
                                                        callback();
                                                    }}, trigger: 'blur'}]">
                                                    <el-input
                                                        :placeholder="`请输入${billChange==='0'?'增加':'减少'}的金额`"
                                                        type="number" :min="0"
                                                        style="width: 470px;line-height:38px"
                                                        v-model="distForm.modifySettleAmount">
                                                        <el-select v-model="billChange" placeholder="请选择"
                                                                   slot="prepend"
                                                                   style="width: 74px;">
                                                            <el-option label="增加" value="0"></el-option>
                                                            <el-option label="减少" value="1"></el-option>
                                                        </el-select>
                                                        <template slot="prefix"><span
                                                            style="color: #000B15;">¥</span></template>
                                                        <span slot="suffix">
                                                    <span style="color: #000B15;padding-right: 10px;">当前结算:
                                                        <span style="color: #ff3b30">
                                                            {{ item.settleAmount || 0 }}
                                                        </span>
                                                    </span>
                                            </span>
                                                    </el-input>
                                                </el-form-item>
                                            </div>
                                            <div style="float: left;padding-left: 10px">
                                                <el-form-item label="变更理由：" label-width="100px"
                                                              prop="settleModifyRemark"
                                                              :rules="[{ required: true, message: '请填写变更理由'}]">
                                                    <el-input placeholder="请输入变更理由，核损明细等信息" type="text"
                                                              v-model="distForm.settleModifyRemark"></el-input>
                                                </el-form-item>
                                            </div>
                                        </div>
                                        <div class="clear" v-if="isGuider">
                                            <div style="float: left">
                                                <el-form-item label="司导结算：" prop="modifyDriverPrice"
                                                              :rules="[{ required: true, message: '请填写金额'},
                                                  {validator:(rule, value, callback) => {
                                                      let price = item.driverPrice || 0;
                                                   if ((price - value<0)&&billChange==='1') {
                                                        callback('减少金额不得大于结算金额');
                                                    } else {
                                                        callback();
                                                    }}, trigger: 'blur'}]">
                                                    <el-input
                                                        :placeholder="`请输入${billChange==='0'?'增加':'减少'}的金额`"
                                                        type="number" :min="0"
                                                        style="width: 470px;line-height:38px"
                                                        v-model="distForm.modifyDriverPrice">
                                                        <el-select v-model="billChange" placeholder="请选择"
                                                                   slot="prepend"
                                                                   style="width: 74px;">
                                                            <el-option label="增加" value="0"></el-option>
                                                            <el-option label="减少" value="1"></el-option>
                                                        </el-select>
                                                        <template slot="prefix"><span
                                                            style="color: #000B15;">¥</span></template>
                                                        <span slot="suffix">
                                                    <span style="color: #000B15;padding-right: 10px;">当前结算:
                                                        <span style="color: #ff3b30">
                                                            {{ item.driverPrice || 0 }}
                                                        </span>
                                                    </span>
                                            </span>
                                                    </el-input>
                                                </el-form-item>
                                            </div>
                                            <div style="float: left;padding-left: 10px">
                                                <el-form-item label="变更理由：" label-width="100px" prop="modifyRemark"
                                                              :rules="[{ required: true, message: '请填写变更理由'}]">
                                                    <el-input placeholder="请输入变更理由，核损明细等信息" type="text"
                                                              v-model="distForm.modifyRemark"></el-input>
                                                </el-form-item>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="isBill||isRecevable||isGuider">
                                        <el-button type="primary"
                                                   style="width: 110px;margin-left: 15px;height: 38px"
                                                   @click="distDierector">
                                            下一步
                                        </el-button>
                                    </div>
                                </div>

                            </el-form>
                        </div>
                        <div v-else>
                            <div>
                                <el-button type="text" icon="el-icon-arrow-left" @click="showNext=false">
                                    上一步
                                </el-button>
                            </div>
                            <el-form label-position="right" class="order-list-table">
                                <div style="float: left;">
                                    <el-form-item label="用户：" label-width="68px" v-if="isRecevable">
                                        <table>
                                            <tr class="font-title-w6">
                                                <td style="width: 107px">原应收金额</td>
                                                <td style="width: 107px">变更金额</td>
                                                <td style="width: 107px">调整后应收金额</td>
                                                <td style="width: 314px">变更理由</td>
                                            </tr>
                                            <tr>
                                                <td>{{ item.receivableAmount | currency }}</td>
                                                <td>{{ distForm.refundAmount | currency }}</td>
                                                <td>
                                                    {{
                                                        item.receivableAmount | formatNumberAdd(Number((receivableChange
                                                        === '0' ? "" : "-") + distForm.refundAmount))
                                                    }}
                                                </td>
                                                <td>{{ distForm.refundRemark }}</td>
                                            </tr>
                                        </table>
                                    </el-form-item>
                                    <el-form-item label="供应商：" label-width="68px" v-if="isBill">
                                        <table>
                                            <tr class="font-title-w6">
                                                <td style="width: 107px">原结算金额</td>
                                                <td style="width: 107px">变更金额</td>
                                                <td style="width: 107px">调整后结算金额</td>
                                                <td style="width: 314px">变更理由</td>
                                            </tr>
                                            <tr>
                                                <td>{{ item.settleAmount | currency }}</td>
                                                <td>{{ distForm.modifySettleAmount | currency }}</td>
                                                <td>
                                                    {{
                                                        item.settleAmount | formatNumberAdd(Number((billChange === '0' ? ""
                                                            : "-") + distForm.modifySettleAmount))
                                                    }}
                                                </td>
                                                <td>{{ distForm.settleModifyRemark }}</td>
                                            </tr>
                                        </table>
                                    </el-form-item>
                                    <el-form-item label="供应商：" label-width="68px" v-if="isGuider">
                                        <table>
                                            <tr class="font-title-w6">
                                                <td style="width: 107px">原结算金额</td>
                                                <td style="width: 107px">变更金额</td>
                                                <td style="width: 107px">调整后结算金额</td>
                                                <td style="width: 314px">变更理由</td>
                                            </tr>
                                            <tr>
                                                <td>{{ item.driverPrice | currency }}</td>
                                                <td :style="`color:${billChange==='0'?' #49B500':'#FF3B30'}`">
                                                    {{
                                                        (billChange === '0' ? "+" : "-")
                                                    }}{{ distForm.modifyDriverPrice | currency }}
                                                </td>
                                                <td :style="`color:${billChange==='0'?' #49B500':'#FF3B30'}`">
                                                    {{
                                                        (item.driverPrice + Number((billChange === '0' ? "" : "-") +
                                                            distForm.modifyDriverPrice)) | currency
                                                    }}
                                                </td>
                                                <td>{{ distForm.modifyRemark }}</td>
                                            </tr>
                                        </table>
                                    </el-form-item>
                                </div>
                            </el-form>
                            <div style="position: absolute;bottom: 0px;right: 10px">
                                <div style="float: right">
                                    <el-button style="width: 110px" class="btn-border-319"
                                               @click="showChange(index)">
                                        取消
                                    </el-button>
                                    <el-button style="width: 110px" type="primary" :loading="isBtn"
                                               @click="orderChange(item)">
                                        确认
                                    </el-button>
                                </div>
                                <div style="float: right;" class="word-desc">
                                    <span>确认变更信息？</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="serveice-note clear"
                         v-if="item.isOrderChange&&item.showIndex==12">
                        <!--style="height: 170px;"-->
                        <div class="clear order-change" v-if="!showNext">
                            <el-form label-position="left" label-width="120px" :model="distForm" ref="distForm">
                                <div style="display: inline-block;float: left">
                                    <div class="clear">
                                        <div style="float: left">
                                            <el-form-item label="司导结算变更：" prop="modifyDriverPrice"
                                                          :rules="[{ required: true, message: '请填写金额'},
                                                  {validator:(rule, value, callback) => {
                                                      let price = item.driverPrice || 0;
                                                   if ((price - value<0)&&billChange==='1') {
                                                        callback('减少金额不得大于结算金额');
                                                    } else {
                                                        callback();
                                                    }}, trigger: 'blur'}]">
                                                <el-input :placeholder="`请输入${billChange==='0'?'增加':'减少'}的金额`"
                                                          type="number" :min="0"
                                                          style="width: 470px;line-height:38px"
                                                          v-model="distForm.modifyDriverPrice">
                                                    <el-select v-model="billChange" placeholder="请选择" slot="prepend"
                                                               style="width: 74px;">
                                                        <el-option label="增加" value="0"></el-option>
                                                        <el-option label="减少" value="1"></el-option>
                                                    </el-select>
                                                    <template slot="prefix"><span
                                                        style="color: #000B15;">¥</span></template>
                                                    <span slot="suffix">
                                                    <span style="color: #000B15;padding-right: 10px;">当前结算:
                                                        <span style="color: #ff3b30">
                                                            {{ item.driverPrice || 0 }}
                                                        </span>
                                                    </span>
                                            </span>
                                                </el-input>
                                            </el-form-item>
                                        </div>
                                        <div style="float: left;padding-left: 10px">
                                            <el-form-item label="变更理由：" label-width="100px" prop="modifyRemark"
                                                          :rules="[{ required: true, message: '请填写变更理由'}]">
                                                <el-input placeholder="请输入变更理由，核损明细等信息" type="text"
                                                          v-model="distForm.modifyRemark"></el-input>
                                            </el-form-item>
                                        </div>
                                    </div>
                                </div>

                                <div style="float: left;">
                                    <el-button type="primary"
                                               style="width: 110px;margin-left: 15px;height: 38px"
                                               @click="distDierector">
                                        下一步
                                    </el-button>
                                </div>
                            </el-form>
                        </div>
                        <div v-else>
                            <div>
                                <el-button type="text" icon="el-icon-arrow-left" @click="showNext=false">
                                    上一步
                                </el-button>
                            </div>
                            <el-form label-position="right" class="order-list-table">
                                <div style="float: left;">
                                    <el-form-item label="供应商：" label-width="68px">
                                        <table>
                                            <tr class="font-title-w6">
                                                <td style="width: 107px">原结算金额</td>
                                                <td style="width: 107px">变更金额</td>
                                                <td style="width: 107px">调整后结算金额</td>
                                                <td style="width: 314px">变更理由</td>
                                            </tr>
                                            <tr>
                                                <td>{{ item.driverPrice | currency }}</td>
                                                <td :style="`color:${billChange==='0'?' #49B500':'#FF3B30'}`">
                                                    {{
                                                        (billChange === '0' ? "+" : "-")
                                                    }}{{ distForm.modifyDriverPrice | currency }}
                                                </td>
                                                <td :style="`color:${billChange==='0'?' #49B500':'#FF3B30'}`">
                                                    {{
                                                        (item.driverPrice + Number((billChange === '0' ? "" : "-") +
                                                            distForm.modifyDriverPrice)) | currency
                                                    }}
                                                </td>
                                                <td>{{ distForm.modifyRemark }}</td>
                                            </tr>
                                        </table>
                                    </el-form-item>
                                </div>
                            </el-form>
                            <div style="position: absolute;bottom: 0px;right: 10px">
                                <div style="float: right">
                                    <el-button style="width: 110px" class="btn-border-319"
                                               @click="showChange(index)">
                                        取消
                                    </el-button>
                                    <el-button style="width: 110px" type="primary" :loading="isBtn"
                                               @click="orderChange(item)">
                                        确认
                                    </el-button>
                                </div>
                                <div style="float: right;" class="word-desc">
                                    <span>确认变更信息？</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--发送事件-->
                    <el-dialog :visible="item.showIndex==='sendEvent'" @close="showChange(index)"
                               title="发送事件">
                        <CreateWorkOrder :order="item"
                                         v-if="item.showIndex==='sendEvent'"
                                         @success="handleOrderWorkOrderRefresh(item,index)"></CreateWorkOrder>
                    </el-dialog>
                    <!--事件信息-->
                    <div class="serveice-note clear" v-if="item.showIndex==='eventDetail'">
                        <OrderWorkOrder :order="item" :ref="'orderWorkOrderRef'+item.orderId"></OrderWorkOrder>
                    </div>
                    <!--飞猪工单-->
                    <div class="serveice-note clear" v-if="item.showIndex==='fliggyWorkOrder'">
                        <FliggyWorkOrder :orderInfo="item" relationType="startRelation"
                                         @onCancel="showChange(index,'fliggyWorkOrder')"></FliggyWorkOrder>
                    </div>
                </div>
                <div class="table-page-number">
                    <div class="block">
                        <el-pagination
                            @current-change="handleCurrentChange"
                            @size-change="handleSizeChange"
                            :current-page="currentPage"
                            :page-size="pageSize"
                            :page-sizes="[10, 50, 100]"
                            layout="sizes,total, prev, pager, next, jumper"
                            :total="totalCount">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </el-main>
    </div>
</template>

<script type="text/ecmascript-6">
import {
    onlyChineseAndLetter,
    isCellphone,
    onlyLettersOrNumber,
    uniqueArray,
    keepTwoDecimal, formatDate, isIDCardNumber
} from '@/common/js/Utils';
import plateCity from '@/common/js/data/plateCity.json';
import orderServer from '../../../urls/orderUrls';
import {getCityData} from '@/urls/index';
import searchForm from '../components/searchForm/';
import orderMain from '../components/orderMain/'
import gaodeMap from '../../../components/gaodeMap/index'
import trackMap from '../../../components/gaodeMap/trackMap'
import {seatModelList, getSeatModel, productFormList, orderStatusList} from "@/data/index";
import dataInfo from "@/data/orderData/data";
import TripInfo from '../components/tripInfo/'
import SeatModel from "@/components/seatModel/index";
import getApolloConfig from "@/common/js/getApolloConfig"
import PassingPoint from '../components/passing-point/'
import BtnFinishBackendDriverOrder from "./btn-finish-backend-driver-order.vue"
import RecordingItinerary from './recording-itinerary.vue'
import BtnFinishTripOrder from './btn-finish-trip-order'
import ChangeOperator from "@/www/pages/orderManage/orderList/change-operator";
import BtnSetVip from "@/www/pages/orderManage/orderList/btn-set-vip";
import CreateWorkOrder from "@/www/pages/workOrder/CreateWorkOrder.vue";
import OrderWorkOrder from "@/www/pages/workOrder/OrderWorkOrder.vue";
import dayjs from "dayjs";
import FliggyWorkOrder from '@/www/pages/workOrder/fliggy/SendMessage.vue'

export default {
    name: 'orderList',
    //定义模版数据
    data() {
        let validateName = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请填写姓名'));
            } else if (!onlyChineseAndLetter(value)) {
                callback(new Error('姓名格式错误'));
            } else {
                callback();
            }
        };
        let validatePhone = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请填写手机号'));
            } else if (!isCellphone(value)) {
                callback(new Error('手机号格式错误'));
            } else {
                callback();
            }
        };
        let validateCarNum = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请填写车牌号'));
            } else if (!onlyLettersOrNumber(value) || value.length < 5 || value.length > 7) {
                callback(new Error('车牌号格式错误'));
            } else {
                callback();
            }
        };
        let validatePrice = (rule, value, callback) => {
            if (!value)
                callback(new Error('请填写司机到手价'))
            else
                callback();
        };
        return {
            plateCity: plateCity,
            cityList: [],
            currentSelectIndex: null,//当前选中项
            resetForm: {},
            payTypeList: dataInfo.payTypeList,
            payChannelList: dataInfo.payChannelList,
            sourceTypeList: dataInfo.sourceTypeList,
            productFormList: productFormList,
            orderList: [],
            serviceInfoList: [],
            serveiceForm: {
                serveiceNote: ''
            },
            distForm: {
                selectShortCity: '沪',
                indemnityAmount: 0,
                settleAmount: 0,
                carBrandModel: [],
                idCardNumber: null
            },
            orderStatusList: orderStatusList,
            modifyStatusCount: 0,
            distRule: {
                touristGuideMobile: [{validator: validatePhone, trigger: 'blur'}],
                guiderMobile: [{validator: validatePhone, trigger: 'blur'}],
                touristGuideName: [{validator: validateName, trigger: 'blur'}],
                guiderName: [{validator: validateName, trigger: 'blur'}],
                selectShortCity: [{required: true, message: '请选择简称', trigger: 'blur'}],
                carNum: [{validator: validateCarNum, trigger: 'blur'}],
                driverPrice: [{validator: validatePrice, trigger: 'blur'}],
                supplierId: [{required: true, message: '请选择供应商', trigger: 'change'}],
                settleAmount: [{required: true, message: '请填写金额', trigger: 'blur'}],
                carBrandModel: [{required: true, message: '请选择品牌型号', trigger: 'change'}],
                carBrand: [{required: true, message: '请输入车辆品牌', trigger: 'change'}],
                carModel: [{required: true, message: '请输入车辆型号', trigger: 'change'}],
                carColor: [{required: true, message: '请填写颜色', trigger: 'blur'}],
                driverHandPrice: [{required: true, message: '请填写金额', trigger: 'blur'}],
            },
            showNext: false,
            showPop: false,
            supplierList: [],
            pageSize: 10,
            currentPage: 1,
            totalCount: 0,
            operatorList: [],
            agencyId: '', // old供应商idsupplierId
            driverList: [],
            driverListShow: '',  // 查询司导库value 1 name 2 phone
            driverLibShow: false,  //是否在司导库
            brandModelLsit: [],
            seatModelList: seatModelList,
            assignmentPrice: '',  // 分派订单司机到手价 格式化
            orderLogList: [],
            trackList: [],
            tripInfo: null,
            receivableChange: "0",
            billChange: "0",
            isRecevable: false,
            isBill: false,
            isGuider: false,
            allSupplier: [],
            isBtn: false,
            completeForm: {
                distance: null,
                useCarTime: null,
                finishTime: null,
                exceedDistanceFee: 0,
                exceedTimeFee: 0,
                faceFee: 0,
                otherFee: 0,
                remark: '无'
            },
            refusalOrderForm: {
                reason: ''//拒绝理由
            },
            freeSupplierIds: {},
            selectedDriverInfo: {},
            params: {
                orderNo: -1
            },
            nowTime: +new Date(),
            changeDriverType: null//重新分配司导类型 0导游 1司机
        }
    },
    props: {
        type: {
            type: String,
            default: 'userCar'
        }
    },
    components: {
        CreateWorkOrder,
        ChangeOperator,
        searchForm,
        orderMain,
        gaodeMap,
        trackMap,
        TripInfo,
        SeatModel,
        PassingPoint,
        BtnFinishBackendDriverOrder,
        RecordingItinerary,
        BtnFinishTripOrder,
        BtnSetVip,
        OrderWorkOrder,
        FliggyWorkOrder
    },
    //计算属性
    computed: {
        orderManageList() {
            return this.orderList;
        },
        freeDistributionOfDrivers() {
            let miniCar = false;
            const {refId} = this.userInfo;
            if (this.currentSelectIndex > -1) {
                let item = this.orderList[this.currentSelectIndex];
                if (item)
                    miniCar = seatModelList.filter(({seat}) => seat < 7).some(({value}) => item.vehicleType == value);
            }
            const {ids, onlyMini} = this.freeSupplierIds;
            if (ids.indexOf(refId) !== -1)
                return true;
            if (miniCar && onlyMini.indexOf(refId) !== -1)
                return true
            return false
        },
        userInfo() {
            return this.$store.state.loginUserInfo
        },
        cacelStatusList() {
            return [
                {name: '用户申请取消', value: '1', num: 0},
                {name: this.userInfo.userType != 1 ? '待供应商确认取消' : '取消待处理', value: '3', num: 0},
                {name: '已取消', value: '2', num: 0},
            ]
        }
    },
    //主件被加载完成
    mounted() {
        this.getServerCity();
        this.getBrandList();
        this.getOrderList({
            sortField: 'create_time',
            sortOrder: 'DESC',
            queryDay: 30,
            orderNo: this.$route.query.orderNo
        });
        this.params.orderNo = this.$route.query.orderNo;
        this.getApolloSupperIds();
        if (this.userInfo.refType === '0')
            this.getOperator();
    },
    activated() {
        if (this.$route.query.orderNo !== this.params.orderNo) {
            this.$refs.searchForm.resetForm({
                orderNo: this.$route.query.orderNo
            });
            this.getOrderList({
                //     sortField: 'create_time',
                //     sortOrder: 'DESC',
                //     queryDay: 30,
                orderNo: this.$route.query.orderNo
            })
            this.params.orderNo = this.$route.query.orderNo;
        }
        if (this.sessionStorage.get("orderListRefresh") && this.$refs['searchForm']) {
            this.sessionStorage.removeItem("orderListRefresh")
            this.$refs['searchForm'].serach();
        }
    },
    //定义事件方法
    methods: {
        handleOrderWorkOrderRefresh(item, index) {
            this.showChange(index)
            this.$refs['orderWorkOrderRef' + item.orderId] && this.$refs['orderWorkOrderRef' + item.orderId].refresh()
        },
        //验证身份证号
        validatorIdCardNumber(rule, value, callback) {
            if (!value)
                return callback('请输入身份证号')
            if (!isIDCardNumber(value))
                callback('请输入有效的身份证号');
            callback();
        },
        //获取apollo配置可以自由分配司机配置
        async getApolloSupperIds() {
            const ret = await getApolloConfig('bms_free_allocation_of_drivers');
            if (ret)
                this.freeSupplierIds = ret
        },
        handleTripInfo(item, index) {
            this.orderList[index] = item;
            this.orderList = JSON.parse(JSON.stringify(this.orderList))
        },
        clearAmountNum(val, name, index) {
            if (!index) return this.distForm[name] = val ? parseInt(val).toFixed(index) : 0;
            this.distForm[name] = keepTwoDecimal(val, index);
        },  // 金额小数点格式化
        async getServerCity() {
            const ret = await getCityData(this, '2');
            if (ret)
                this.cityList = ret;
        },
        async getOperator() {//获取运营商list
            let res = await this.http('/galaxyLoginuserApi/getAdminLoginUserOrgInfoByType', {refType: '2'}, 'POST');
            if (res && res.success) {
                this.operatorList = res.data;
            }

        },
        async getDriverLib(type) {
            let form = {};
            if (type === 2) form.driverMobile = this.distForm.guiderMobile;
            if (type === 1) form.driverName = this.distForm.guiderName;
            let res = await this.http('/galaxyOrder/getDriverListBySupplier', form, 'POST');
            if (res.success && res.data) {
                this.driverList = res.data.filter(({cancelAccountStatus}) => cancelAccountStatus !== 1)
            } else {
                this.driverList = [];
            }
        },
        onInputQuery(value) {
            clearTimeout(this.timer);
            this.driverListShow = value;
            this.timer = setTimeout(() => {
                // this.driverList = null;
                if (value == 1) {
                    if (this.distForm.guiderName) {
                        this.getDriverLib(1);
                    } else this.driverList = [];
                }
                if (value == 2) {
                    if (this.distForm.guiderMobile && isCellphone(this.distForm.guiderMobile)) {
                        this.getDriverLib(2);
                    } else this.driverList = [];
                }
            }, 200);
        },
        //输入司机手机号 丢失焦点自动带入符合要求司机信息
        onInputBlur() {
            if (this.userInfo.userType == 1 && !this.freeDistributionOfDrivers && (!this.driverList || this.driverList.length === 0))
                this.distForm = {};
            setTimeout(() => {
                this.driverList = [];
            }, 500)
        },
        handName(item = {}) {
            this.selectedDriverInfo = item;
            let form = {}
            if (item.carNumberPlate) {
                form.selectShortCity = item.carNumberPlate.substring(0, 1);
                form.carNum = item.carNumberPlate.substr(1).toUpperCase();
            }
            form.carId = item.carId;
            form.guiderMobile = item.driverMobile;
            form.guiderName = item.driverName;
            form.carType = Number(item.carType || 0);
            form.carBrandModel = [item.carBrand, item.carModel];
            form.carBrand = item.carBrand;
            form.carModel = item.carModel;
            form.carColor = item.carColor;
            form.driverId = item.id;
            form.carOwner = item.carOwner;
            form.isRecharge = item.isRecharge;
            form.rechargeType = item.rechargeType;
            form.validityEndTime = item.validityEndTime;
            form.idCardNumber = item.idCardNumber;
            form.supplierId = item.driverSupplierId;
            this.distForm = form;
            this.driverListShow = 0;

        },
        search(data) {
            this.currentPage = 1;
            this.getOrderList(data);
            this.params.orderNo = undefined
        },
        //单独更新订单项 先通过id查询到信息 然后单独更新这条数据
        async updateOrderItem(orderNo) {
            try {
                let url = '/galaxyOrder/backend/orderList';
                if (this.type === "trip") url = "/galaxyOrder/BackendListTripOrder";
                this.loadingShow();
                let res = await orderServer.orderListHttp(this, {orderNo}, url);
                if (res.success && res.data) {
                    let orderList = res.data.data;
                    orderList.forEach(item => {
                        item.tripInfoDialog = 1;
                        if (item.departure && item.departure.indexOf('address') != -1) {
                            item.departure = JSON.parse(item.departure);
                            item.isDeparture = true;
                        }
                        if (item.destination && item.destination.indexOf('address') != -1) {
                            item.destination = JSON.parse(item.destination);
                            item.isDestination = true;
                        }
                        this.productFormList.forEach(item1 => {
                            if (item.productForm == item1.value) {
                                item.productFormName = item1.name;
                            }
                        })
                        this.orderStatusList.forEach(os => {
                            if (item.orderStatus == os.value) item.orderStatusName = os.name;
                            if (item.orderStatus == 71) {
                                const cancelInfo = this.cacelStatusList.find(cs => {
                                    return item.cancelStatus == cs.value;
                                })
                                item.orderStatusName = cancelInfo.name;
                            }
                        })
                        this.sourceTypeList.forEach(rs => {
                            if (item.sourceType == rs.value) item.sourceName = rs.name;
                        })
                        this.payTypeList.forEach(p => {
                            if (item.paymentType == p.value) item.payTypeName = p.name;
                        })
                        this.payChannelList.forEach(p => {
                            if (item.paymentChannel == p.value) {
                                item.payChannelName = p.name;
                                item.subjectName = p.subjectName
                            }
                        })
                        if (item.confirmPayment == 1)
                            item.operatorPaymentAmount = item.paymentAmount;
                        else item.operatorPaymentAmount = item.receivableAmount;

                        if (item.confirmActualSettle == 1)
                            item.operatorActualSettle = item.actualSettleAmount;
                        else item.operatorActualSettle = item.settleAmount
                    })
                    this.orderList = this.orderList.map((item) => ({
                        ...(orderNo === item.orderNo ? orderList[0] : item)
                    }))
                }
            } finally {
                this.loadingHide();
            }
        },
        async getOrderList(data) {//订单列表
            this.orderList = [];
            this.nowTime = +new Date()
            this.loadingShow();
            let form = JSON.parse(JSON.stringify(data));
            if (form.tripTime) {
                form.tripStartTime = +new Date(form.tripTime[0]);
                form.tripEndTime = +new Date(form.tripTime[1]);
                delete form.tripTime;
            }
            if (form.createOrderTime) {
                form.createOrderStartTime = +new Date(form.createOrderTime[0]);
                form.createOrderEndTime = dayjs(form.createOrderTime[1]).endOf('d').valueOf();
                delete form.createOrderTime;
            }
            if (form.orderStatus === 0) delete form.orderStatus;
            // console.log(form);
            form.pageSize = this.pageSize;
            form.pageIndex = this.currentPage;
            delete form.orderStatus;
            delete form.cancelStatus;
            // delete form.modifyStatus;
            delete form.confirmOrder;
            delete form.distributionMethod;
            let url = '/galaxyOrder/backend/orderList';
            if (this.type === "trip") url = "/galaxyOrder/BackendListTripOrder";
            this.loadingShow();
            let res = await orderServer.orderListHttp(this, form, url);
            this.loadingHide();
            if (res.success && res.data) {
                this.totalCount = res.data.total;
                let orderList = res.data.data;
                orderList.forEach(item => {
                    item.tripInfoDialog = 1;
                    if (item.departure && item.departure.indexOf('address') != -1) {
                        item.departure = JSON.parse(item.departure);
                        item.isDeparture = true;
                    }
                    if (item.destination && item.destination.indexOf('address') != -1) {
                        item.destination = JSON.parse(item.destination);
                        item.isDestination = true;
                    }
                    this.productFormList.forEach(item1 => {
                        if (item.productForm == item1.value) {
                            item.productFormName = item1.name;
                        }
                    })
                    this.orderStatusList.forEach(os => {
                        // console.log(os.name);
                        if (item.orderStatus == os.value) item.orderStatusName = os.name;
                        if (item.orderStatus == 71) {
                            const cancelInfo = this.cacelStatusList.find(cs => {
                                return item.cancelStatus == cs.value;
                            })
                            item.orderStatusName = cancelInfo.name;
                        }
                    })
                    this.sourceTypeList.forEach(rs => {
                        if (item.sourceType == rs.value) item.sourceName = rs.name;
                    })
                    this.payTypeList.forEach(p => {
                        if (item.paymentType == p.value) item.payTypeName = p.name;
                    })
                    this.payChannelList.forEach(p => {
                        if (item.paymentChannel == p.value) {
                            item.payChannelName = p.name;
                            item.subjectName = p.subjectName
                        }
                    })
                    if (item.confirmPayment == 1)
                        item.operatorPaymentAmount = item.paymentAmount;
                    else item.operatorPaymentAmount = item.receivableAmount;

                    if (item.confirmActualSettle == 1)
                        item.operatorActualSettle = item.actualSettleAmount;
                    else item.operatorActualSettle = item.settleAmount
                })
                this.orderList = orderList;
                this.loadingHide();
            }
            this.isBtn = false;

            this.loadingHide();
        },
        async getServiceInfo(orderId, index) {//客服信息
            this.serveiceForm.serveiceNote = null;
            if (!this.orderList[index].isOrderChange) return false;
            let form = {
                orderId: orderId
            };
            let res = await orderServer.OrderRemarkList(this, form);
            console.log(res);
            if (res.success) {
                this.serviceInfoList = res.data;
            }
        },
        addServiceInfo(item, index) {//添加客服信息
            this.isBtn = true;
            this.$refs.serveiceForm[0].validate(async (valid) => {
                if (valid) {
                    let form = {
                        "orderId": item.orderId,
                        "orderNo": item.orderNo,
                        "orderRemark": this.serveiceForm.serveiceNote
                    };
                    let res = await orderServer.addOrderRemark(this, form);
                    console.log(res);
                    this.serveiceForm.serveiceNote = '';
                    if (res.success) {
                        this.serveiceNote = null;
                        this.getServiceInfo(item.orderId, index);

                        this.$message.success('添加成功')
                    } else {
                        this.messageError(res.errors[0].message)
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
                this.isBtn = false;
            });
        },
        async distDierector(type, item) {
            this.$refs.distForm[0].validate(async (valid) => {
                if (valid) {
                    if (type === 1 && item.resourceUseCar == 1) {
                        //分配司机下一步
                        //淮北市诚智汽车服务有限公司 下的司机必须是充值包年服务司机才能分配
                        let supplierId = this.Debug ? '' : '8a9fadc469e2c2210169fc7c0b58001c';
                        const {isRecharge, rechargeType, validityEndTime} = this.distForm
                        if (item.resourceSupplierId === supplierId) {
                            let msg = ''
                            if (isRecharge !== 0 || rechargeType !== 2)
                                msg = '请选择已开通车辆服务包年的司机'
                            if (validityEndTime < item.reserveTime)
                                msg = `订单用车时间(${formatDate(item.reserveTime, 'yyyy-MM-dd hh:mm')})不在司机充值包年服务时间(${formatDate(validityEndTime, 'yyyy-MM-dd hh:mm')})内`;
                            if (validityEndTime < +new Date())
                                msg = `司机包年充值服务已过期，充值到期时间(${formatDate(validityEndTime, 'yyyy-MM-dd hh:mm')})`;
                            if (msg)
                                return this.$alert(`该司机暂不能接单。${msg}`, '提示', {
                                    confirmButtonText: '确定',
                                    type: 'error'
                                });
                        }
                        //判断用户是否编辑过用户名和手机号
                        if ((this.selectedDriverInfo.driverName != this.distForm.guiderName)
                            || (this.selectedDriverInfo.driverMobile != this.distForm.guiderMobile)) {
                            //如果是能自由输入到司机的供应商 就去掉已经选择的司机id
                            if (this.freeDistributionOfDrivers) {
                                delete this.distForm.driverId
                                // delete this.distForm.supplierId
                            } else return this.messageError('请从下拉框内选择司机')
                        }
                        //判断司机是否能够自由分配
                        if (this.freeDistributionOfDrivers || this.distForm.supplierId) {
                            this.showNext = true;
                        } else this.messageError('请从下拉框内选择司机')
                    } else this.showNext = true;
                }
            })
        },
        //分配司导
        async addDierector(item) {
            this.isBtn = true;
            let form = {};
            if (item.resourceUseCar == 0) {
                //如果是分配 向导服务 需要将司机和导游信息同时提交
                if (['18', '21'].includes(item.productForm)) {
                    //分配导游
                    if (this.changeDriverType == 0) {
                        form = {
                            ...item.distributionGuiderDto,
                            orderId: item.orderId,
                            touristGuideMobile: this.distForm.touristGuideMobile,
                            touristGuideName: this.distForm.touristGuideName,
                        }
                    } else {
                        //分配司机
                        form = {
                            ...item.distributionGuiderDto,
                            orderId: item.orderId,
                            guiderMobile: this.distForm.guiderMobile,
                            guiderName: this.distForm.guiderName,
                            carNumber: this.distForm.selectShortCity + ("" + this.distForm.carNum).toUpperCase(),
                            driverId: this.distForm.driverId,
                            carBrand: this.distForm.carBrandModel[0],
                            carModel: this.distForm.carBrandModel[1],
                            carColor: this.distForm.carColor,
                            carType: this.distForm.carType,
                            carOwner: this.distForm.carOwner,
                            driverPrice: this.distForm.driverPrice,
                            supplierId: this.distForm.supplierId,
                            idCardNumber: this.distForm.idCardNumber,
                            carId: this.distForm.carId,
                            sourceType: item.sourceType,
                        }
                    }
                } else {
                    form = {
                        orderId: item.orderId,
                        touristGuideMobile: this.distForm.touristGuideMobile,
                        touristGuideName: this.distForm.touristGuideName,
                    }
                }
                if (item.id) form.id = item.id
            }
            if (item.resourceUseCar == 1) {
                form = {
                    orderId: item.orderId,
                    guiderMobile: this.distForm.guiderMobile,
                    guiderName: this.distForm.guiderName,
                    carNumber: this.distForm.selectShortCity + ("" + this.distForm.carNum).toUpperCase(),
                    driverId: this.distForm.driverId,
                    carBrand: this.distForm.carBrandModel[0],
                    carModel: this.distForm.carBrandModel[1],
                    carColor: this.distForm.carColor,
                    carType: this.distForm.carType,
                    carOwner: this.distForm.carOwner,
                    driverPrice: this.distForm.driverPrice,
                    supplierId: this.distForm.supplierId,
                    idCardNumber: this.distForm.idCardNumber,
                    carId: this.distForm.carId,
                    sourceType: item.sourceType
                }
            }
            this.loadingShow();
            let res = {};
            if (item.resourceUseCar == 0) res = await orderServer.touristDistributionGuider(this, form);
            if (item.resourceUseCar == 1) res = await orderServer.distributionGuider(this, form);
            if (res.success) {
                // await this.getOrderList(this.$refs['searchForm'].searchForm, true);
                //如果是在待分配司导界面就将当前项直接移出 全部tab就改状态
                if (this.$refs['searchForm'].searchForm.orderStatus === '31') {
                    this.orderList = this.orderList.filter(({orderNo}) => orderNo !== item.orderNo);
                    this.loadingHide();
                } else
                    await this.updateOrderItem(item.orderNo);
                this.distForm = {
                    selectShortCity: '沪',
                    indemnityAmount: 0,
                    settleAmount: 0,
                    carBrandModel: []
                }
                this.$message.success('分配司机成功！');
                //分配成功发送事件，关闭通知弹窗
                window.bus.$emit('closeNotice', item.orderNo)
            } else {
                this.loadingHide();
            }
            this.isBtn = false;
        },
        updateOrderList() {
            this.getOrderList(this.$refs['searchForm'].searchForm, true);
        },
        updateCTripCost(index) {
            let orderList = JSON.parse(JSON.stringify(this.orderList));
            orderList[index].visibleCostShow = true;
            this.orderList = orderList;
        },
        async addSupplier(item) {
            try {
                this.loadingShow();
                this.isBtn = true;
                let form = {
                    "orderId": item.orderId,
                    "supplierId": this.distForm.supplierId,
                    "settleAmount": this.distForm.settleAmount,
                    "supplierNumber": this.distForm.supplierNumber,  //供应商短编号
                    "supplierName": this.distForm.supplierName, // 供应商名字
                }

                let res = null;
                if (item.orderStatus == 21) res = await orderServer.addSupplier(this, form);
                if (item.orderStatus == 23) res = await orderServer.reallocateSupplier(this, form);
                if (res.success) {
                    await this.updateOrderItem(item.orderNo)
                    this.$message.success('添加成功');
                    // this.getOrderList(this.$refs['searchForm'].searchForm, true);
                } else {
                    this.messageError(res.errors[0].message)
                }
                this.isBtn = false;
            } finally {
                this.loadingHide();
            }
        },
        showChange(index, type, id) {
            this.orderList.forEach((item, idx) => {
                item.isDierect = false;
                if (index != idx)
                    item.isOrderChange = false;
                else {
                    if (item.showIndex != type && type)
                        item.isOrderChange = true;
                    else if (item.showIndex == type && !item.isOrderChange)
                        item.isOrderChange = true;
                    else item.isOrderChange = false;
                }
            })
            this.orderList[index].showIndex = this.orderList[index].showIndex !== type ? type : undefined;
            this.showNext = false;
            this.distForm = {
                selectShortCity: '沪',
                indemnityAmount: 0,
                settleAmount: 0,
                carType: 1
            };
            if (this.orderList[index].isOrderChange) {
                switch (type) {
                    case 5:
                        this.getServiceInfo(id, index);
                        break;
                    case 6:
                        if (this.orderList[index].orderStatus == 61) this.getTrackInfo(this.orderList[index].orderId);
                        break;
                    case 7:
                        this.getOrderLog(this.orderList[index].orderId);
                        break;
                    //重新分配司导
                    case 8:
                        this.getSupplierList(this.orderList[index].productOperatorId);
                        this.changeDriverType = this.orderList[index].resourceUseCar;
                        break;
                    case 10:
                        this.distForm.settleAmount = this.orderList[index].actualSettleAmount;
                        this.getSupplierList(this.orderList[index].productOperatorId);
                        break;
                    default:
                        break;
                }
            }
            this.receivableChange = '0';
            this.billChange = '0';
            //记录当前选择的操作项
            this.currentSelectIndex = index;
            this.$forceUpdate();
        },
        async orderChange(item) {
            this.isBtn = true;
            let form = {
                actualSettleAmount: this.distForm.actualSettleAmount,
                indemnityAmount: this.distForm.indemnityAmount,
                orderId: item.orderId,
                refundAmount: this.distForm.refundAmount,
                refundRemark: this.distForm.refundRemark
            }
            let res = null,
                form1, form2;

            switch (item.showIndex) {
                case 1:
                    res = await orderServer.cancelOrder(this, form);
                    break;
                case 2:
                    form1 = {
                        orderId: item.orderId,
                    }
                    if (this.isRecevable) {
                        form1.refundAmount = (this.receivableChange === '0' ? "-" : "") + this.distForm.refundAmount;
                        form1.refundRemark = this.distForm.refundRemark;
                    }
                    if (this.isBill) {
                        form1.modifySettleAmount = (this.billChange === '0' ? "-" : "") + this.distForm.modifySettleAmount;
                        form1.settleModifyRemark = this.distForm.settleModifyRemark;
                    }
                    if (this.isGuider) {
                        form1.modifyDriverPrice = (this.billChange === "0" ? "-" : "") + this.distForm.modifyDriverPrice;
                        form1.modifyRemark = this.distForm.modifyRemark;
                        res = await orderServer.supplierUpdatePrice(this, form1);
                    } else res = await orderServer.orderChangeHttp(this, form1);
                    break;
                case 3:
                    form.cancelId = item.orderCancellationDto.id;
                    res = await orderServer.cancelOrder(this, form);
                    break;
                case 12:
                    form2 =
                        {
                            modifyDriverPrice: (this.billChange === "0" ? "-" : "") + this.distForm.modifyDriverPrice,
                            modifyRemark: this.distForm.modifyRemark,
                            orderId: item.orderId
                        }
                    res = await orderServer.supplierUpdatePrice(this, form2);
                    break;
                default:
                    break;
            }
            if (res.success) {
                switch (item.showIndex) {
                    case 1:
                        this.$message.success('订单取消成功');
                        break;
                    case 2:
                        this.$message.success('订单变更修改成功');
                        break;
                    case 12:
                        this.$message.success('订单变更修改成功');
                        break;
                    default:
                        break;
                }
                // this.currentPage = 1;
                this.getOrderList(this.$refs['searchForm'].searchForm, true);
            } else {
                // this.messageError(res.errors[0].message)
                this.isBtn = false;
            }
        },
        async acceptOrderModification(item) {
            this.isBtn = true;
            let form = {
                modifyId: item.orderModificationDto.id
            }
            this.showPop = false;
            let res = await orderServer.acceptOrderModification(this, form);
            if (res.success) {
                this.$message.success('订单变更成功');
                // this.currentPage = 1;
                this.getOrderList(this.$refs['searchForm'].searchForm, true);
            } else {
                this.messageError(res.errors[0].message)
            }
            this.isBtn = false;
        },
        async acceptOrderCancellation(item) {
            console.log(item);
            this.isBtn = true;
            let form = {
                cancelId: item.orderCancellationDto.id,
                orderId: item.orderId
            }
            this.showPop = false;
            let res = await orderServer.acceptOrderCancellation(this, form);
            console.log(res);
            if (res.success) {
                this.$message.success('订单取消成功');
                // this.currentPage = 1;
                this.getOrderList(this.$refs['searchForm'].searchForm, true);
            } else {
                this.messageError(res.errors[0].message)
            }
            this.isBtn = false;
        },
        async withdrawOrderCancellation(item) {
            this.isBtn = true;
            let form = {
                cancelId: item.orderCancellationDto.id
            };
            item.showPop = true;
            this.showPop = false;
            let res = await orderServer.withdrawOrderCancellation(this, form);
            this.showPop = false;
            if (res.success) {
                this.$message.success('撤销订单取消成功');
                // this.currentPage = 1;
                this.getOrderList(this.$refs['searchForm'].searchForm, true);
            } else {
                this.messageError(res.errors[0].message)
            }
            this.isBtn = false;
        },
        async rejectCancelApplication(item) {
            this.isBtn = true;
            let form = {
                cancelId: item.orderCancellationDto.id
            };
            this.showPop = false;
            let res = await orderServer.rejectCancelApplication(this, form);
            console.log(res);
            this.showPop = false;
            if (res.success) {
                this.$message.success('撤销订单取消成功');
                // this.currentPage = 1;
                this.getOrderList(this.$refs['searchForm'].searchForm, true);
            } else {
                this.messageError(res.errors[0].message)
            }
            this.isBtn = false;
        },
        async withdrawOrderModification(item) {
            this.isBtn = true;
            let form = {
                modifyId: item.orderModificationDto.id
            };
            let res = await orderServer.withdrawOrderModification(this, form);
            console.log(res);
            if (res.success) {
                this.$message.success('撤销订单变更成功');
                // this.currentPage = 1;
                this.getOrderList(this.$refs['searchForm'].searchForm, true);
            } else {
                this.messageError(res.errors[0].message)
            }
            this.isBtn = false;
        },
        async operatorConfirm(index) {//供应商确认订单
            this.isBtn = true;
            let form = {
                orderId: this.orderList[index].orderId
            }
            let res = await orderServer.supplierConfirm(this, form);
            console.log(res);
            if (res.success) {
                this.$message.success('订单确认成功');
                // this.currentPage = 1;
                this.getOrderList(this.$refs['searchForm'].searchForm, true);
            } else {
                this.messageError(res.errors[0].message)
            }
            this.isBtn = false;
        },
        async operatorShortMess(index) {//重发确认短信给出行人
            this.isBtn = true;
            let form = {
                id: this.orderList[index].orderId
            }
            let res = await orderServer.operatorShortMess(this, form);
            if (res.success) {
                this.$message.success('成功重发确认短信');
                // this.currentPage = 1;
                this.getOrderList(this.$refs['searchForm'].searchForm, true);
            } else {
                this.messageError(res.errors[0].message)
            }
            this.isBtn = false;
        },
        async operatorRefuse(index) {//运营商拒绝订单
            const formRefs = this.$refs[`refusalOrderForm${index}`];
            let valid = true;
            if (formRefs && formRefs.length > 0) {
                formRefs[0].validate((v) => valid = v)
                if (!valid)
                    return false;
            }
            this.isBtn = true;
            let form = {
                orderId: this.orderList[index].orderId,
                reason: this.refusalOrderForm.reason
            }
            this.loadingShow()
            let res;
            if (this.userInfo.userType == 1) {
                res = await orderServer.supplierRefuse(this, form)
            } else {
                res = await orderServer.operatorRefuse(this, form);
            }
            if (res.success) {
                this.$message.success('拒绝成功');
                await this.getOrderList(this.$refs['searchForm'].searchForm, true);
            } else {
                this.messageError(res.errors[0].message)
            }
            this.isBtn = false;
            this.loadingHide()

        },
        async restoreNewOrder(index) {  // 恢复新订,订单状态变更为新订待分配(拉回总部)
            this.isBtn = true;
            let form = {
                id: this.orderList[index].orderId
            }
            let res = await orderServer.getRestoreAssignSupplier(this, form)
            if (res.success) {
                this.$message.success('已恢复新订');
                this.getOrderList(this.$refs['searchForm'].searchForm, true);
            } else {
                this.messageError(res.errors[0].message)
            }
            this.isBtn = false;
        },
        async getSupplierList(id) {
            const ret = await orderServer.getSupplierInfoByOperatorId(this, {operatorId: id})
            if (ret.success) {
                this.supplierList = ret.data;
            }
        },
        // 旅游订单完成
        async getFinishTouristOrder(item) {
            const ret = await orderServer.getFinishTouristOrder({id: item.orderId})
            if (ret.success) {
                this.$message.success('订单已完成');
                this.getOrderList(this.$refs['searchForm'].searchForm, true);
            } else {
                this.messageError(ret.errors[0].message)
            }
        },
        selectSupplier(index) {
            this.distForm.supplierName = this.supplierList[index].supplierName;
            this.distForm.supplierId = this.supplierList[index].id;
            this.distForm.supplierNumber = this.supplierList[index].supplierId; //供应商短编号
            this.distForm.supplierName = this.supplierList[index].supplierName;  //供应商名字

        },
        selectSupplier1(id) {
            const item = this.supplierList.find(item => {
                return (id === item.id);
            })
            this.distForm.supplierId = item.id;
            this.distForm.operatorId = item.operatorId;
            this.distForm.supplierName = item.supplierName;
        },
        handleSizeChange(size) {
            this.pageSize = size;
            this.handleCurrentChange(1);
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.currentPage = val;
            this.getOrderList(this.$refs['searchForm'].searchForm);
        },
        cancelClose() {
            let orderList = JSON.parse(JSON.stringify(this.orderList));
            orderList.forEach(item => {
                item.showPop = false;
                item.showProcessOrderChangePop = false;
                item.refuseShow = false;
                item.confirmShow = false;
                item.restoreOrderShow = false;
                item.shortMessageShow = false;
                item.isService = false;
                item.isGrab = false;
                item.isCancelGrab = false;
                item.isShowMap = false;
                item.isShowLog = false;
            })
            this.orderList = orderList;
            this.$forceUpdate();

        },
        popoverShow(val, index) {
            let orderList = JSON.parse(JSON.stringify(this.orderList));
            orderList.forEach((item, idx) => {
                item[val] = index == idx ? true : false;
            })
            this.refusalOrderForm.reason = '';
            this.orderList = orderList;
            this.$forceUpdate();
        },
        handleCancellation(item, index) {
            let orderList = JSON.parse(JSON.stringify(this.orderList));
            orderList[index].showPop = true;
            this.orderList = orderList;
        },
        openPDF(url) {
            window.open(url)
        },
        async dispatchGrab(item) {
            this.isBtn = true;
            this.$refs.distForm[0].validate(async (valid) => {
                if (valid) {
                    let res = await this.http(this.distForm.type ? '/galaxyOrder/manualSendSupplierOrderGrab ' :
                        '/galaxyOrder/manualSendOrderGrab', {
                        id: item.orderId,
                        driverPrice: !this.distForm.type ? this.distForm.driverHandPrice : undefined,
                        settleAmount: this.distForm.type ? this.distForm.driverHandPrice : undefined
                    }, 'POST')

                    if (res.success && res.data) {
                        this.$message.success('分派抢单成功');
                        this.getOrderList(this.$refs['searchForm'].searchForm, true);
                    } else {
                        this.messageError(res.errors[0].message)
                    }
                }
                this.isBtn = false;
            })
        },  //  发送到抢单广场
        async dispatchCancelGrab(item) {
            this.isBtn = true;
            let res = await this.http('/galaxyOrder/cancelGrabOrder', {id: item.orderId}, 'POST')
            if (res.success && res.data) {
                this.$message.success('取消抢单成功');
                this.getOrderList(this.$refs['searchForm'].searchForm, true);
            } else {
                this.messageError(res.errors[0].message)
            }
            this.isBtn = false;
        },  // 取消抢单
        async getBrandList() {
            let res = await this.http('/galaxyStationApi/doCarBrandModelList', {}, 'POST');
            if (res.success) {
                let brandModelLsit = uniqueArray(res.data, 'carBrand');
                let carList = [];
                brandModelLsit.forEach(item => {
                    let list = {
                        value: item.carBrand,
                        label: item.carBrand,
                        children: []
                    }
                    res.data.forEach(item1 => {
                        if (item.carBrand == item1.carBrand) {
                            let data = {
                                value: item1.carModel,
                                label: item1.carModel,
                                carType: item1.carType
                            }
                            list.children.push(data);
                        }

                    })
                    carList.push(list);
                })
                this.brandModelLsit = carList;
            }
        },
        async getTrackInfo(id) {
            let res = await this.http(`/galaxyOrder/getOrderTrackInfo/${id}`);
            console.log(res);
            if (res.success) this.trackList = res.data;
        },
        async getOrderLog(id) {
            this.orderLogList = [];
            const res = await this.http(`/galaxyOrder/doGetOrderAuditLogByOrderId/${id}`);
            // console.log(res);
            if (res.success) {
                this.orderLogList = res.data;
                res.data.forEach(item => {
                    if (item.operateContent.indexOf('坐标') != -1) {
                        const idx = item.operateContent.indexOf('坐标');
                        let info = item.operateContent.substring(idx + 3);
                        info = info.replace('(', '').replace(')', '');
                        // const data = info.split(',');
                        const link = `https://uri.amap.com/marker?position=${info}`
                        item.operateContent = '<p>' + item.operateContent.replace(info, `<a href="${link}" target="_blank">${info}</a>`) + '</p>'
                        // console.log(item.operateContent,12312312132123, idx);
                    }
                })
            }
        },
        getTripInfo(val) {
            console.log(val);
        },
        carNumChange() {
            this.distForm.carNum = ("" + this.distForm.carNum).toUpperCase()
        },
        formatCarModel(type) {
            let info = {};
            if (type) {
                info = getSeatModel(parseInt(type));
                // console.log(info, type);
            }
            return info;
        },
        async cancelDistribution(item) {//取消分配司导
            this.isBtn = true;
            let form = {
                orderId: item.orderId
            };
            this.showPop = false;
            let res = await orderServer.cancelDistributeGuider(this, form);
            console.log(res);
            this.showPop = false;
            if (res.success) {
                this.$message.success('分配司导取消成功');
                // this.currentPage = 1;
                this.getOrderList(this.$refs['searchForm'].searchForm, true);
            } else {
                this.messageError(res.errors[0].message)
            }
            this.isBtn = false;
        },
        async getAllSupplier() {
            let form = {};
            const res = await orderServer.getAllSupplier(this, form);
            if (res.success) this.allSupplier = res.data;
        },
        selectCarBrandModel(val) {
            if (val) {
                const brand = this.brandModelLsit.find(b => {
                    return (val[0] === b.value);
                })
                const model = brand.children.find(c => {
                    return (val[1] === c.value);
                })
                this.distForm.carType = model.carType;
            } else this.distForm.carType = ""
        },
        //客服手动完成携程订单
        async onCompleteSubmit({orderId}) {
            this.$refs['completeForm'][0].validate(async (valid) => {
                if (valid) {
                    this.loadingShow();
                    let form = {
                        // ...this.completeForm,
                        orderId
                    }
                    // form.useCarTime = +new Date(form.useCarTime);
                    // form.finishTime = +new Date(form.finishTime);
                    const ret = await this.http('/galaxyOrder/finishCtripCharteredCarOrder', form, 'POST');
                    if (ret.success && ret.data) {
                        await this.getOrderList(this.$refs['searchForm'].searchForm, true);
                        this.$message.success('已完成');
                    }
                    this.loadingHide();


                }
            })
        },
        //用户手动输入车辆品牌和型号
        handleChangeCarBrandModel() {
            const {carBrand, carModel} = this.distForm;
            this.distForm.carBrandModel = [carBrand, carModel];
        },
        //更新某一项订单的状态
        handleChangeOrderStatus(item) {
            const index = this.orderList.findIndex(({orderId}) => orderId === item.orderId)
            if (index > -1)
                this.$set(this.orderList, index, item)
        }
    }
}
</script>

